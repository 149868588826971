import React, { useEffect, useState } from 'react'
import { Header } from '../Header'
import { useSelector } from 'react-redux';
import Footer from '../Footer';
import Axios from '../../services';
import '../assets/css/jobs.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
// import { Modal } from 'react-bootstrap'
import CustomModal from '../CustomModal';
import Loader from '../section/Loader';

const Jobs = () => {
    const auth = useSelector(state => state.auth);
    // console.log(auth,'auth')
    const [showModal, setShowModal] = useState(false);
    const [Load, setLoad] = useState(true);

    const [data, SetData] = useState();
    const fetchData = () => {
        Axios.get(`api/getoutsidejobs`)
            .then(res => {
                // console.log(res.data["all jobs"], 'data')
                SetData(res.data["all jobs"]);
                setLoad(false)
            });
    }
    useEffect(() => {
        fetchData();
    }, [Load])
    const toggleModal = () => {

        //  console.log(showModal,'toggle');
        setShowModal(!showModal);
    };

    return (
        <>
           {(Load) ? <Loader /> :
        <>
            <Header auth={auth} />
            <div style={{ marginTop: "100px" }}>
                <div className={`automotivejobcard bg-white`}

                >
                    
                        {
                            data && data.map((item) => (
                                <div className="row p-3 col-12 col-sm-10 bg-seconday border w-100 my-2 mx-auto">
                                <>
                                    <div className="col-12 col-md-6  ">
                                        <div className="col-12 d-flex justify-content-between ">
                                            <div className="profile d-flex">
                                                <div className='d-flex flex-column'>
                                                    <h2 className="text-orange mb-0 mt-1">{item.companyname}</h2>
                                                    <p className="post_date text-gray fs-12 fw-bold"> {new Date(item.updated_at).toISOString().split('T')[0]}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="">
                                            <div className="h5 col-12 mt-2">Description</div>
                                            <p className='fs-12 ml-3 w-75'>{item.job_description.split(' ').slice(0, 50).join(' ')}</p>
                                        </div>
                                        <div className="col-12 d-flex align-items-center justify-content-between mt-4 ms-3">
                                            <div className='d-flex '>
                                                <div className='d-flex flex-column border-end pe-3'>
                                                    <div className="d-flex align-items-center">
                                                        <i className="fa fa-user mr-1" style={{ fontSize: "13px" }}></i>
                                                        <span className="fs-13">  Role</span>
                                                    </div>
                                                    <div className="text-orange text-center fs-13">{item.role}</div>
                                                </div>
                                                <div className='d-flex mx-3 flex-column align-items-center ps-3'>
                                                    <div className="d-flex align-items-baseline" >
                                                        <i className="fa fa-dollar-sign mr-1" style={{ fontSize: "13px" }}></i>
                                                        <span className=" fs-13 ">Salary</span>
                                                    </div>
                                                    <div className="text-orange fs-13">{item.salary}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5  jobs_location  ">
                                        {/* <Location data={location} /> */}
                                        <div className="row p-3 bg-seconday ms-2 ">
                                            <div className='d-flex icon_spans flex-column'>
                                                <div className='d-flex first-child   border-end pe-3'>
                                                    <span className="   fs-13">Address :</span>
                                                    <div className="text-orange fs-13">{`${item.gate_no} ${item.city}`}</div>
                                                </div>
                                                <div className='d-flex second-child    border-end pe-3 ps-3'>
                                                    <span className="   fs-13">Gender :</span>
                                                    <div className="text-orange fs-13">{item.gender}</div>
                                                </div>
                                                <div className='d-flex  third-child    border-end pe-3 ps-3'>
                                                    <span className="   fs-13 ">Required Experience :</span>
                                                    <div className="text-orange fs-13">{`${item.experience} year`}</div>
                                                </div>
                                                <div className='d-flex  four-child    ps-3'>
                                                    <span className="   fs-13">Vacancies :</span>
                                                    <div className="text-orange fs-13">{item.vacancies}</div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center mt-2">

                                                {/* {
                          information &&  information.info.length !== 0 ? (
                              <div className={`btn bg_orange   border-0 rounded-0 mt-3 mb-2 w-25  align-items-center ${applied ? 'disabled' : ''}`} onClick={() => handleEasyApply(id, user_id)} >{applied ? 'Applied' : 'Easy Apply'}</div>
                              ):
                              (
                                    <div className={`btn bg_orange   border-0 rounded-0 mt-3 mb-2 w-25  align-items-center`} onClick={toggleModal} >Apply</div>
                            )
                        } */}
                                            </div>
                                            <div className="col-12 mt-5 justify-content-end ms-2 " >
                                                {
                                                    console.log(auth, 'insilde the conent')
                                                }
                                                {
                                                    
                                                        <a href='https://hairwecut.co.uk/portal' target='_blank' className="submit"  >Apply</a>
                                                }

                                            </div>


                                        </div>
                                    </div>
                                </>
                    </div>
                            ))}
                </div>
            </div>
            <Footer />
            {
                //  console.log(showModal,'insidle the modal')
//                 <CustomModal isOpen={showModal} onClose={toggleModal}>
//                     <h2>Application</h2>
//                     <p>Personal Information Form</p>
//                     <form>
//                         <div className="mb-3">

//                             <input type="text" placeholder='Full Name' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
//                         </div>
//                         <div className="mb-3">
//                             <input type="number" placeholder='Contect No' className="form-control" id="  " />
//                         </div>
//                         <div className="mb-3">
//                             <input type="email" placeholder='Email' className="form-control" id="  " />
//                         </div>
//                         <div className="mb-3">
//                             <input type="text" placeholder='Gate / Street No' className="form-control" id="  " />
//                         </div>
//                         <div className="mb-3">
//                             <input type="text" placeholder='City' className="form-control" id="  " />
//                         </div>
//                         <div className="mb-3">
//                             <input type="text" placeholder='Postal Code' className="form-control" id="  " />
//                         </div>
//                         <p>Experience Information</p>
//                         <div className="mb-3">
//                             <input type="text" placeholder='Experience as Barber' className="form-control" id="  " />
//                         </div>
//                         <p>Previous Salon(s) Worked At</p>
//                         <div className="mb-3">
//                             <input type="text" placeholder='Salon Name' className="form-control" id="  " />
//                         </div>
//                         <div className="mb-3">
//                             <input type="text" placeholder='Address' className="form-control" id="  " />
//                         </div>
//                         <div className="mb-3">
//                             <input
//                                 type="text"
//                                 placeholder="Date of Employment"
//                                 className="form-control"
//                                 onClick={(event) => { event.target.type = 'date'; }}
//                             />
//                         </div>
//                         <div className="mb-3">
//                             <input
//                                 type="text"
//                                 placeholder="Date of Termination"
//                                 className="form-control"
//                                 onClick={(event) => { event.target.type = 'date'; }}
//                             />
//                         </div>
//                         <div className="mb-3">
//                             <input type="text" placeholder='Position/Role' className="form-control" id="  " />
//                         </div>
//                         <div className="mb-3">
//                             <textarea className="form-control" placeholder='Reason for Leaving' id="exampleFormControlTextarea1" rows="3"></textarea>
//                         </div>
//                         <p>Education and Certifications</p>
//                         <div className="mb-3">
//                             <input type="text" placeholder='Barber Licence No' className="form-control" id="  " />
//                         </div>
//                         <div className="mb-3">
//                             <input type="text" placeholder='Institute Name' className="form-control" id="  " />
//                         </div>
//                         <div className="mb-3">
//                             <input type="text" placeholder='Address' className="form-control" id="  " />
//                         </div>
//                         <div className="mb-3">
//                             <textarea className="form-control" placeholder='Other Relevant Certifications or Training' id="exampleFormControlTextarea1" rows="3"></textarea>
//                         </div>
//                         <p>Skills and Availability</p>
//                         <div className="mb-3">
//                             <textarea className="form-control" placeholder='Skills and Expertise' id="exampleFormControlTextarea1" rows="3"></textarea>
//                         </div>
//                         <div className="form-group">
//     <select className="form-control" name="appType">
//         <option value="">-- Availability --</option>
//         <option value="full_time">Full-time</option>
//         <option value="part_time">Part-time</option>
//         <option value="flexible">Flexible/Casual</option>
//         <option value="weekdays">Weekdays</option>
//         <option value="weekends">Weekends</option>
//         <option value="evenings">Evenings</option>
//     </select>
// </div>




// <div className="d-flex justify-content-center">
//                         <button type="submit" className="submit">Submit</button>
// </div>
//                     </form>
//                     {/* Your personal information form here */}
//                 </CustomModal>
            }
        </>
}
        </>
    )
}


export default Jobs