import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
 
class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '',};
  }
 
  handleChange = address => {
    this.setState({ address });
  };
 
  handleSelect = (address,placeId, suggestion) => {
    geocodeByAddress(address)
      .then(results => {
       let latelong =  getLatLng(results[0]);

          latelong.then(latelong => {
            this.setState({address:suggestion.description});
            let address = {...latelong, des: suggestion.description };
            this.props.setLocation({address:address})
          });
          this.setState({address:''});
      })
      .catch(error => console.error('Error', error));
  };
 
  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Post Code / Complete Address',
                className: 'location-search-input',
              })}
              style={{background:this.props.background,
                                            height: "55px",
                                            border: "2px solid "+this.props.border+"",
                                            lineHeight:" 52px",
                                            color:this.props.inputcolor,
                                            width:"100%",
                                            padding:"10px 15px",
                                            borderRadius:" 0"}}
            />
            <div className="autocomplete-dropdown-container" style={{background:"#fff",width:"100%", color:this.props.textcolor,fontSize:"12px"}}>
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    style={{background:"#9e8a78",paddingLeft:"50px;",}}                  >
                    <span style={{margin:"0px !important",background:"#9e8a78",zIndex:"2000",lineHeight:"5px !important"}}>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput;