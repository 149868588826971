import React, { useState } from 'react';

import Axios from '../../services';

import Footer from './../Footer';
import Header from './../Header';
import Loader from './../section/Loader';
import LocationSearchInput from './../section/LocationSearchInput';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BecomeaPartner = () => {

    const [values, setValues] = useState({ name: '', salon: '', contact: '', address: '', lat: '', lng: '', email: '', password: '', password_confirmation: '' });
    const [location, setLocation] = useState({ address: '' });
    const [errors, setError] = useState([]);
    const [Load,setLoad] =  useState(false);



    const handleSubmit = (e) => {

        e.preventDefault(); // its set the default form status not refresh the page
        setLoad(true);
        setError([]);
        const data = {
            name: values.name,
            salon: values.salon,
            contact: values.contact,
            email: values.email,
            password: values.password,
            password_confirmation: values.password_confirmation,
            lat: location.address.lat,
            lng: location.address.lng,
            address: location.address.des,
        };

        Axios.post('api/barber/register', data)
            .then(res => {
                setLoad(false)
                toast.dark("Request has been Submitted");
                setLocation([])
                setValues(
                    {
                        name: '', salon: '', contact: '', email: '', password: '', password_confirmation: ''
                    })
            })
            .catch(e => {
                setLoad(false)
                setError(e.response.data.Error);
            });
    }

    const handleChange = (e) => {
        setValues(values => ({ ...values, [e.target.name]: e.target.value }));
    }



    return (
        <>
            {(Load) ?
            <Loader />
            :''
            }
            <ToastContainer />
            <Header />
            <section className="page_header d-flex align-items-center" style={{ padding: "100px", height: "auto" }}>
                <div className="container">
                    <div className="section_heading text-center mb-40 wow fadeInUp" data-wow-delay="300ms">
                        <div className="row" >
                            <div className="col-md-8 offset-md-2">

                                <form id="form" onSubmit={handleSubmit} className="form-horizontal appointment_form" style={{ background: "rgba(0,0,0,0.7)" }}>
                                    <div className="book_content">
                                        <h2>Signup As Barber</h2>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6 padding-10">
                                            <input type="text" id="barber_name" onChange={handleChange} value={values.name} name="name" className="form-control" placeholder="Name" />
                                            <span className="text-danger">{(errors.name == '') ? '' : errors.name}</span>

                                        </div>
                                        <div className="col-md-6 padding-10">
                                            <input type="text" id="shop_name" onChange={handleChange} value={values.salon} name="salon" className="form-control" placeholder="Enter Your Business Name" />
                                            <span className="text-danger">{(errors.salon == '') ? '' : errors.salon}</span>
                                        </div>
                                    </div>

                                    <div className="form-group row">

                                        <div className="col-md-6 padding-10">
                                            <input type="number" id="phone" onChange={handleChange} value={values.contact} name="contact" className="form-control" placeholder="Your Phone No" />
                                            <span className="text-danger">{(errors.contact == '') ? '' : errors.contact}</span>
                                        </div>

                                        <div className="col-md-6 padding-10">
                                            <div className="form-group">
                                                <LocationSearchInput setLocation={setLocation} background='transparent' inputcolor='#fff' textcolor='#fff' border='rgba(255,255,255,0.3)' />
                                                <span className="text-danger">{(errors.address == '') ? '' : errors.address}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row">

                                        <div className="col-md-6 padding-10">
                                            <input type="email" id="email" onChange={handleChange} value={values.email} name="email" className="form-control" placeholder="Your Email" />
                                            <span className="text-danger">{(errors.email == '') ? '' : errors.email}</span>
                                        </div>

                                        <div className="col-md-6 padding-10">
                                            <input type="password" onChange={handleChange} value={values.password} name="password" className="form-control" placeholder="Passwrod" />
                                            <span className="text-danger">{(errors.password == '') ? '' : errors.password}</span>
                                        </div>
                                        <div className="col-md-6 padding-10">
                                            <input type="password" onChange={handleChange} value={values.password_confirmation} name="password_confirmation" className="form-control" placeholder="Confirm Password" />
                                        </div>

                                    </div>
                                    <button id="app_submit" className="default_btn" onClick={handleSubmit} type="submit">SignUp</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <style jsx>{`
        @media screen and (max-width: 1000px) {
          .page_header {
            padding: 100px 10px !important;
          }
        }
      `}</style>
        </>
    )
}

export default BecomeaPartner;