import React from 'react';
import Footer from './../Footer';
import Header from './../Header';
import Post from './../assets/img/post-1.jpg';
import Brands from './../section/Brands';

export default function About() {

    return (
        <>
            <Header />

            <section className="page_header d-flex align-items-center">
                <div className="container">
                    <div className="section_heading text-center mb-40 wow fadeInUp" data-wow-delay="300ms">
                        <h2>About Us</h2>
                        <div className="heading-line"></div>
                    </div>
                </div>
            </section>

            <section className="about_section bd-bottom padding">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-12 xs-padding wow fadeInLeft" data-wow-delay="300ms">
                            <div className="section_heading">
                                <h2>About Us</h2>
                                <p>Welcome to Hair We Cut, where innovation meets tradition in the art of haircare. Founded in 2024, we embarked on a mission to transform how men access and experience haircare services. From the heart of London, we have created a unique platform that blends the convenience of digital technology with the craftsmanship of traditional barbering. Our services range from on-demand haircuts and grooming delivered to your doorstep, to a comprehensive marketplace offering the finest haircare products and business opportunities.
                                    <br /> <br />At Hair WE Cut, we believe that everyone deserves to look their best without the hassle of traditional salon booking. That's why we've partnered with the best in the business, ensuring every service and product on our platform meets our high standards of quality and satisfaction. Our e-commerce platform is not just a marketplace; it's a community where barbers, stylists, and salons can thrive, share, and grow together.
                                    <br /> <br />Our commitment extends beyond just services and products. We are pioneering a job portal dedicated to the haircare industry, connecting talent with opportunities, and shaping the future of barbering and styling careers.
                                </p>
                            </div>
                            <div className="section_heading">
                                <h2>Vision Statement</h2>
                                <p>Our vision is to revolutionize the haircare industry by creating a comprehensive ecosystem that empowers professionals and delights customers. We aim to be the ultimate platform where expertise meets convenience, enabling seamless access to top-tier services, products, and opportunities. By fostering a community of skilled artisans and discerning customers, we envision a future where every haircare need, whether it's a personal service at home or a professional ambition, is fulfilled with excellence and innovation.
                                </p>
                            </div>
                            <div className="section_heading">
                                <h2>Mission Statement</h2>
                                <p>Our mission is to provide an unparalleled platform that connects men with premium haircare services and products, anytime, anywhere. We are committed to partnering with the finest barbers and salons to offer a spectrum of services, from haircuts to beard grooming, all accessible via a user-friendly mobile and web interface. Simultaneously, we aim to empower our partners by providing them with a dynamic e-commerce platform to buy and sell products, lease spaces, and grow their businesses. We are dedicated to cultivating a marketplace that not only serves the immediate needs of our customers but also contributes to the thriving ecosystem of the haircare industry. </p>
                            </div>
                        </div>
                        {/* <div className="col-md-6 xs-padding wow fadeInRight" data-wow-delay="300ms">
                            <div className="about_video">
                                <img src={Post} alt="img" />
                                <div className="play-icon"><a href="#"><i className="ti-control-play"></i></a></div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <Brands />
            <Footer />
        </>
    )
}