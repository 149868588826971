import React, { useState } from 'react';
import Auth from './../reducers/authReducer';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';

import Logo from './assets/img/final-logo.png';
import Cart from './assets/img/cart.png';


import { useSelector} from 'react-redux'; 

export function Header({ auth},props) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const CartProducts =  useSelector((state) => state.cart)
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    return (
        <>
            <header id="header" className="header-section">
                <div className="logo">
                        <a href="/" className="navbar-brand"><img src={Logo} width="80" /></a>
                </div>
                <div className="container-fluid">
                    <nav className="navbar">
                        <i className='fa fa-bars d-lg-none'  onClick={toggleMenu}></i>
                            <div id="mainmenu" className="mainmenu">
                                <ul className={`nav ${isMenuOpen ? 'show' : ''}`}>
                                    <li><Link to="/">Home</Link></li>
                                    {/* <li><Link to="/services">Services</Link></li> */}
                                    <li><Link to="/product">Products</Link></li>
                                    <li><Link to="/becomeapartner">Become A Partner</Link></li>
                                    <li><Link to="/marketplace">Market Place</Link></li>
                                    <li><Link to="/jobs">Job Portal</Link></li>
                                    <li><Link to="about">About Us</Link></li>
                                    <li><Link to="/contact">Contact Us</Link></li>
                                    {(auth.isAuthenticated) ?
                                        <>
                                        <li><Link to="/dashboard">Dashboard</Link></li>
                                        </>
                                        :

                                        <li><Link to="/signin">Sign In</Link></li>
                                    }
                                    <li class="dropdown cart_dropdown">
                                        <Link to="/cart">
                                        <img src={Cart} width="20" />
                                        <span class="cart_count">{CartProducts.totalProduct}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        <div className="d-flex menu-wrap align-items-center">
                            <div className="header-btn">
                                <Link to="/appointment" className="menu-btn">Make Appointment</Link>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(Header);