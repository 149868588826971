import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import classnames from "classnames";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './../section/Loader';


import Footer from './../Footer';
import Header from './../Header';

class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      contact: "",
      confirm: "",
      errors: {},
      Load: false,
    };
  }

  // componentDidMount() {
  //   // If logged in and user navigates to Register page, should redirect them to dashboard
  //   if (this.props.auth.isAuthenticated) {
  //     this.setState({ Load: false });
  //     toast.dark("Request has been Submitted");
  //     this.props.history.push("/dashboard");
  //   }
  // }



  // static getDerivedStateFromProps = (nextProps, prevState) =>
  //   nextProps.errors === prevState.errors
  //     ? null
  //     : { errors: nextProps.errors };


  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };


  onSubmit = async (e) => {

    e.preventDefault();
    this.setState({ Load: true });
    const newUser = {

      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
      contact: this.state.contact,
    };
    // console.log("userData",newUser);
    const resp = await this.props.registerUser(newUser);
    if (resp.redirect) {
      console.log("Res:",resp.redirect)
      this.setState({ Load: false });
      this.setState({
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        contact: "",
        confirm: "",
      }
      );
      toast.dark("Request has been Submitted");
      // this.props.history.push({ pathname: "/signin", status: true });
    }
  };

  render() {
    const { errors } = this.state;

    return (
      <>
        {(this.state.Load) ?
          <Loader />
          : ''
        }
        <ToastContainer />
        <Header />
        <section className="page_header d-flex align-items-center" style={{ padding: "100px", height: "auto" }}>
          <div className="container">
            <div className="section_heading text-center mb-40 wow fadeInUp" data-wow-delay="300ms">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <form action="" noValidate onSubmit={this.onSubmit} className="form-horizontal appointment_form" style={{ background: "rgba(0,0,0,0.7)" }}>
                    <div className="book_content">
                      <h2>Signup</h2>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6 padding-10">
                        <input
                          onChange={this.onChange}
                          value={this.state.name}
                          id="name"
                          type="text"
                          placeholder="Name"
                          className={classnames("form-control")}
                        />
                        <span className="text-danger">{errors?.Error?.name || ''}</span>
                      </div>
                      <div className="col-md-6 padding-10">
                        <input
                          onChange={this.onChange}
                          value={this.state.email}
                          id="email"
                          type="email"
                          placeholder="Your E-Mail"
                          className={classnames("form-control")}
                        />
                        <span className="text-danger">{errors?.Error?.email || ''}</span>
                      </div>
                    </div>

                    <div className="form-group row">

                      <div className="col-md-6 padding-10">
                        <input
                          onChange={this.onChange}
                          value={this.state.password}
                          id="password"
                          type="password"
                          placeholder="Password"
                          className={classnames("form-control")}
                        />
                        <span className="text-danger">{errors?.Error?.password || ''}</span>

                      </div>

                      <div className="col-md-6 padding-10">
                        <input
                          onChange={this.onChange}
                          value={this.state.password_confirmation}
                          error={errors.password_confirmation}
                          id="password_confirmation"
                          type="password"
                          placeholder="Confirm Password "
                          className={classnames("form-control")}
                        />

                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6 padding-10">
                        <input
                          onChange={this.onChange}
                          value={this.state.contact}
                          error={errors.contact}
                          id="contact"
                          type="text"
                          placeholder="Contact #"
                          className={classnames("form-control")}
                        />
                        <span className="text-danger">{errors?.Error?.contact || ''}</span>

                      </div>
                    </div>
                    <button className="default_btn" type="submit">SignUp</button>
                    <p style={{ textAlign: "left" }}>If you Already have account  <Link to="/signin" > SignIn</Link></p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(withRouter(Register));
