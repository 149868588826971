import React from 'react'
import ReactPlayer from 'react-player'

export default function Vedio() {

    return (
        <>
            <div className="hero_section d-flex align-items-center">
                {/* <div className="video_bg">
                    <ReactPlayer className="video_bg" playing muted loop  url='https://www.youtube.com/watch?v=3pFH7FQu5so'/>
                </div> */}
                <div className="container">
                    <div className="hero_content align-center">
                        <h3>Welcome to Hair We Cut</h3>
                        <h1>Where Innovation Meets Grooming Excellence</h1>
                        {/* <p>Our barbershop is the territory created purely for males who appreciate<br /> premium quality, time and
                            flawless look.</p> */}
                    </div>
                </div>
            </div>
        </>
    )
}