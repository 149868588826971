import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

import moment from 'moment'

import Axios from '../../services';
import Loader from './../section/Loader';

import { Link, Route, Redirect, useLocation } from 'react-router-dom';
import Footer from './../Footer';
import Header from './../Header';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';





function Dashboard() {



    const [appointment, setAppoinments] = useState([]);
    const [canceled, setCancled] = useState(0);
    const [completed, setcompleted] = useState(0);
    const [total, settotal] = useState(0);
    const [sold, setSold] = useState([]);
    const [Load, setLoad] = useState(true);

    const Location = useLocation();

    useEffect(() => {
        fetchData();
        fetchProduct();
        return () => {
            fetchData();
            fetchProduct();
        }
    }, [Load]);

    useEffect(() => {
        if (Location.state == true) {
            toast.dark("Your Payment has Successfully Made");
        }
    }, [Location]);

    const fetchData = () => {
        Axios.get('api/customer/customerappoinments')
            .then(res => {
                setAppoinments([...res.data.appointmentscom,...res.data.appointmentspending])
                setCancled(res.data.canceled)
                setcompleted(res.data.completed)
                settotal(res.data.totalapp)
                // setSold(res.data.order.soldproduct)
                // console.log(res.data.order,'sold')
                setLoad(false)
            })
    }
     const fetchProduct = async () => {
            try {
                 const token = localStorage.getItem('jwtToken');
                //  console.log(token,'token')
                const response = await fetch('https://hairwecut.co.uk/hairwe/api/customer/customerorders', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setSold([...data.ordercom,...data.orderpending]);
                console.log(data,'data1')
                console.log([...data.ordercom,...data.orderpending],'data')
                // setLoading(false);
            } catch (error) {
                // setError(error);
                // setLoading(false);
            }
        };


    const appcancle = async (id) => {
        setLoad(true)
        const res = await Axios.post('api/customer/cancleapp', { id: id })
            .then(res => {
                toast.error("Appointment Cancled")
            })
    }


    const onLogoutClick = (e) => {
        e.preventDefault();
        localStorage.clear();
        window.location.reload(false);


    };


    // const { user } = this.props.auth;

    const getReview = (status = '', rating, appoId = '') => {
        let content = []

        if (status === 'Completed') {
            // If Completed Then Given The Review
            content.push(<Link to={'/review/' + appoId} className="bg-warning" style={{ padding: ".30rem .50rem", color: "#fff", borderRadius: "10px" }}>Rating</Link>)

        } else if (status === 'Paid') {
            // If Status is paid Then It can be Cancled
            content.push(<button className="btn btn-danger btn-sm" type="button" onClick={() => { appcancle(appoId) }} ><i className="fa fa-times" style={{ fontSize: "12px", padding: "2px 2px" }}></i></button>)

        } else if (status === 'Review') {
            // If Review Then Show the Start Of related The Review
            for (let i = 1; i <= rating; i++) {
                content.push(<i key={i} style={{ color: "gold" }} className="fa fa-star"></i>)
            }
        } else if (status === 'Canceled') {
            
            content.push(<span className="bg-danger" style={{ padding: ".30rem .50rem", color: "#fff", borderRadius: "10px" }}>{status}</span>)
        }else {

            content.push(<span className="bg-info" style={{ padding: ".30rem .50rem", color: "#fff", borderRadius: "10px" }}>{status}</span>)

        }
        return content
    }



    return (
        <>
            <ToastContainer />
            {(Load) ? <Loader /> :
                <>
                    <Header />

                    <section className="page_header d-flex align-items-center">
                        <div className="container">
                            <div className="section_heading text-center mb-40 wow fadeInUp" data-wow-delay="300ms">
                                <h2>Dashboard</h2>
                                <div className="heading-line"></div>
                            </div>
                        </div>
                    </section>

                    <section className="about_section bd-bottom padding">
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-12 col-md-12 sm-padding wow fadeInLeft" data-wow-delay="300ms">
                                    <ul className="nav nav-tabs">
                                        <li className="active" ><a data-toggle="tab" href="#home"><i className="fa fa-th"></i> Dashboard</a></li>
                                        <li><a data-toggle="tab" href="#menu2"><i className="fa fa-check"></i> Appointments</a></li>
                                        <li><a data-toggle="tab" href="#menu3"><i className="fa fa-shopping-cart"></i> Product Order</a></li>
                                        <li><button type="button" className="btn btn-sm" onClick={onLogoutClick} ><i className="fa fa-sign-out"></i> Logout</button> </li>
                                    </ul>
                                </div>


                                <div className="col-lg-12 col-md-12 wow fadeInRight" data-wow-delay="300ms">
                                    <div className="tab-content">
                                        <div id="home" className="tab-pane fade in active show">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-6 sm-padding wow fadeInUp" data-wow-delay="200ms">
                                                    <div className="service_box">
                                                        <h5>Total Bookings</h5>
                                                        <h4>{total || 0}</h4>

                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 sm-padding wow fadeInUp" data-wow-delay="300ms">
                                                    <div className="service_box">
                                                        <h5>Completed Bookings</h5>
                                                        <h4>{completed || 0}</h4>

                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 sm-padding wow fadeInUp" data-wow-delay="400ms">
                                                    <div className="service_box">
                                                        <h5>Pendding Bookings</h5>
                                                        <h4>{canceled || 0}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="menu2" className="tab-pane fade show">

                                            <h3>Appointments List</h3>
                                            <table className="table table-striped">
                                                <thead>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Barber #</th>
                                                    <th>Timing</th>
                                                    <th>Date </th>
                                                    <th>Service </th>
                                                    <th>Type </th>
                                                    <th>Service Type</th>
                                                    <th>Status</th>
                                                </thead>
                                                <tbody>
                                                    {
                                                     appointment && appointment.map((appo, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>{(appo.barber || {}).name || ''}</td>
                                                                    <td>{(appo.slot || {}).slot_no || ''}</td>
                                                                    <td>{(appo.slot || {}).from_time || ''} / {(appo.slot || {}).to_time || ''}</td>
                                                                    <td>{appo.date || ''}</td>
                                                                    <td>{(appo.service || {}).title || ''}</td>
                                                                    <td>{appo.service_type || ''}</td>
                                                                    <td>{appo.appType || ''}</td>
                                                                    <td>
                                                                        {getReview(appo.status || '', (appo.rating || {}).rating || null, appo.id)}
                                                                    </td>

                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>

                                        </div>

                                        <div id="menu3" className="tab-pane fade show">

                                            <h3>Product List</h3>
                                            <table className="table table-striped">
                                                <thead>
                                                    <th>#</th>
                                                    <th>Product Name</th>
                                                    <th>Price</th>
                                                    <th>Quantity</th>
                                                    <th>Status</th>
                                                    <th>Date </th>
                                                </thead>
                                                <tbody>
                                                    {
                                                   sold &&   sold.map((sold, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    {
                                                                      console.log(sold.soldproduct[0]?.product?.product_name || '', 'sold')
                                                                    }
                                                                    {/* <td>Name</td> */}
                                                                    <td>{sold.soldproduct[0]?.product?.product_name|| ''}</td>
                                                                    <td>{sold.soldproduct[0]?.price || 0}</td>
                                                                    <td>{sold.soldproduct[0]?.quatity || 0}</td>
                                                                    <td>{sold.soldproduct[0]?.status || ''}</td>
                                                                    <td>{moment(sold.created_at).format('d-m-Y')}</td> 

                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>


                    <Footer />
                </>
            }
        </>
    );
}

Dashboard.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Dashboard);
