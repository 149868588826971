import React,{useState} from 'react';
import {Link, useHistory } from "react-router-dom";
import Footer from './../Footer';
import Header from './../Header';

import Axios from '../../services';

import Loader from './../section/Loader';


function Review(props) {

    const history = useHistory()
    const [values,setValues] = useState(0);
    const [Error,setError] = useState([]);
    const [Load,setLoad] =  useState(false);

    const handleSubmit = (e) =>{
        e.preventDefault();
        setLoad(true);
        const data = {
            app_id:props.match.params.id,
            value:values,
        };

        Axios.post('api/customer/rating',data)
        .then(res => {
            setLoad(false);
            history.push('/dashboard')
        })
        .catch(res =>{
            setLoad(false);
            setError(res.response.data.Error);
        });
    }




    // console.log("id",)


    return (
        <>
        {(Load) ?
          <Loader />
          :''
        }
            <Header />
            <section className="page_header d-flex align-items-center" style={{padding:"100px",height:"auto"}}>
                <div className="container">
                    <div className="section_heading mb-40" data-wow-delay="300ms">
                        <div className="row">
                            <div className="col-md-4 offset-4">
                                <form onSubmit={handleSubmit} style={{background:"#fff",padding:"20px"}}>
                                    <div className="center">
                                        
                                        <div className="stars">
                                            <input type="radio" id="five" onChange={(e) => {setValues(e.target.value)}} name="rate" value="5" />
                                            <label for="five"></label>
                                            <input type="radio" id="four" onChange={(e) => {setValues(e.target.value)}} name="rate" value="4" />
                                            <label for="four"></label>
                                            <input type="radio" id="three" onChange={(e) => {setValues(e.target.value)}} value={values.rate} name="rate" value="3" />
                                            <label for="three"></label>
                                            <input type="radio" id="two" onChange={(e) => {setValues(e.target.value)}} name="rate" value="2" />
                                            <label for="two"></label>
                                            <input type="radio" id="one" onChange={(e) => {setValues(e.target.value)}} name="rate" value="1" />
                                            <label for="one"></label>
                                        </div>
                                    </div>
                                    <input type="submit" className="btn btn-block" style={{background:"#9e8a78",color:"#fff",borderRadius:"0px"}} value="Review" />
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            
        </>
    )
}

export default Review
