import React, { Component }  from 'react';

// SLike SLider
import Slider from "react-slick";

import Logo from './../assets/img/logo.png';
// About Section
import sponser from './../assets/img/sponsor-1.png';
import sponser2 from './../assets/img/sponsor-2.png';
import sponser3 from './../assets/img/sponsor-3.png';
import sponser4 from './../assets/img/sponsor-4.png';
import sponser5 from './../assets/img/sponsor-5.png';


export default class Brands extends Component{

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000
        };

        return (
            <>
                <div className="sponsor_section bg-grey padding">
                    <div className="container">
                        <Slider {...settings}>
                            <div className="sponsor_item">
                                    <img src={sponser} alt="sponsor-image" />
                            </div>
                            <div className="sponsor_item">
                                    <img src={sponser2} alt="sponsor-image" />
                            </div>
                            <div className="sponsor_item">
                                    <img src={sponser3} alt="sponsor-image" />
                            </div>
                            <div className="sponsor_item">
                                    <img src={sponser4} alt="sponsor-image" />
                            </div>
                            <div className="sponsor_item">
                                    <img src={sponser5} alt="sponsor-image" />
                            </div>
                            <div className="sponsor_item">
                                    <img src={sponser} alt="sponsor-image" />
                            </div>
                            <div className="sponsor_item">
                                    <img src={sponser2} alt="sponsor-image" />
                            </div>
                        </Slider>
                        
                    </div>
                </div>
            </>
        )
    }
}