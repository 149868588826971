export const GET_ERRORS       = "GET_ERRORS";
export const USER_LOADING     = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

// Cart Type
export const ADD_TO_CART      = 'ADD_TO_CART';
export const REMOVE_ITEM      = 'REMOVE_ITEM';
export const SUB_QUANTITY     = 'SUB_QUANTITY';
export const ADD_QUANTITY     = 'ADD_QUANTITY';
export const ADD_SHIPPING     = 'ADD_SHIPPING';
export const ORDER_PLACED     = 'ORDER_PLACED';
