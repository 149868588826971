import React from 'react';

import Logo from './../assets/img/logo.png';

// About Section
import about1 from './../assets/img/about-1.jpg';
import about2 from './../assets/img/about-2.jpg';
import about3 from './../assets/img/about-3.jpg';

export default function HomeAbout() {
    

    return (
        <>
            <section id="about" className="about_section bd-bottom padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="about_content align-center">
                                <h3 className="wow fadeInUp" data-wow-delay="100ms">Introducing</h3>
                                <h2 className="wow fadeInUp" data-wow-delay="200ms">Hair We Cut</h2>
                                <img className="wow fadeInUp" data-wow-delay="500ms" src={Logo} alt="logo" width="150" />
                                <p className="wow fadeInUp text-justify" data-wow-delay="600ms">In <b> HAIR WE CUT</b> , we are experts in the online barber system. We aim to provide you smart and trendy haircuts and treatments. We have a team of professionally trained barbers who are experts in multiple hairs and skincare treatments. Now you can easily get a professional haircut and skin treatments at your home. The barbers of <b> HAIR WE CUT</b> are near your home town. We aim to connect with all the barbers who have a core skill of hair and skin services and want to continue their business. </p>
                                <a href="" className="default_btn wow fadeInUp" data-wow-delay="600ms">More About Us</a>
                            </div>
                        </div>
                        <div className="col-md-6 d-none d-md-block">
                            <div className="about_img"> 
                                <img src={about1} alt="idea-images" className="about_img_1 wow fadeInLeft"
                                    data-wow-delay="200ms" />
                                <img src={about2} alt="idea-images" className="about_img_2 wow fadeInRight"
                                    data-wow-delay="400ms" />
                                <img src={about3} alt="idea-images" className="about_img_3 wow fadeInLeft"
                                    data-wow-delay="600ms" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}