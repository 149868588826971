import React, { useState, useEffect } from 'react';

import Axios from '../../services';

import GoogleMapReact from 'google-map-react';
import Marker from './../section/Marker';
import useLocation from './Api/CurrentLocation';

import Footer from './../Footer';
import Header from './../Header';


function BarberMap() {

    const [barbers, setDeals] = useState([])
    const [Load, setLoad] = useState(true);
    const location = useLocation();

    useEffect(() => {
        if (location.loaded) {
            setLoad(false)
            fetchData();
            return () => {
                fetchData();
            }
        }
    }, [Load, location.loaded]);


    const fetchData = () => {

        Axios.post('api/customer/barbers', location)
        .then(res => {
            if (res && res.data && Array.isArray(res.data)) {
                // console.warn(res.data,'Data')
                setDeals(res.data);
                console.warn(res.data,location,'barbers dd');
            }
        })

    }

    return (

        <>
            <Header />
            <div style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyDVShzlNwwpUcswCgBEve7t6n9Q3eeD8PI' }}
                    defaultCenter={{ lat: 0, lng: 0 }}
                    center={{
                        lat: location.lat,
                        lng: location.lng
                    }}
                    defaultZoom={13}
                    yesIWantToUseGoogleMapApiInternals
                // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                >

                    {barbers.map((barber, i) =>
                    {
                        // console.log(barber,'barberdetils')
                        return (
                        <Marker key={i} lat={barber.lat} lng={barber.lng} data={
                            {
                                name: barber.salon,
                                // booking: (barber.appoitment[0] || '').booking || 0,
                                rating: (barber.rating || {}).totalrating || 0,
                                id: barber.user_id,
                            }
                        } />


                    )}
                    )}

                </GoogleMapReact>
            </div>
            <Footer />
        </>
    );
}

export default BarberMap
