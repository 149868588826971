import React, { useEffect, useState } from 'react';
import { Header } from '../Header';
import Footer from '../Footer';
import { useSelector } from 'react-redux';
import '../assets/css/marketplace.css'
import Axios from '../../services';
import Loader from '../section/Loader';

const MarketPlace = () => {
  const auth = useSelector(state => state.auth);
  const [activeTab, setActiveTab] = useState('table1'); 
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [Load,SetLoad] =useState(true);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setLoading(true); // Set loading state to true when tab is clicked
  };

  const fetchData = () => {
    let endpoint = '';
    let dataKey = ''; // Initialize a variable to store the key for accessing data in res.data
  
    switch (activeTab) {
      case 'table1':
        endpoint = 'api/getallmarketrents';
        dataKey = 'all rents chairs';
        break;
      case 'table3':
        endpoint = 'api/getallmarketsalon';
        dataKey = 'all salon';
        break;
      case 'table2':
        endpoint = 'api/getallmarketproduct';
        dataKey = 'all products'; 
        break;
      default:
        endpoint = '';
        dataKey = ''; 
    }
  
    Axios.get(endpoint)
      .then(res => {
        setData(res.data[dataKey]);
        setLoading(false); 
        SetLoad(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false); // Reset loading state even if there's an error
      });
  }
  

  useEffect(() => {
    fetchData();
  }, [Load,activeTab]);

  return (
    <>
    {(Load) ? <Loader /> :
    <>
      <Header auth={auth} />
      {/* Tab Buttons */}
      <div style={{ marginTop: "95px" }}>
        <div className='d-flex mx-1 mx-sm-5 flex-wrap border-bottom tab-div'>
          <button className={`mr-3 ${activeTab === 'table1' ? 'active' : ''}`} onClick={() => handleTabClick('table1')}>Business/Premises for Rent</button>
          <button className={`mr-3 ${activeTab === 'table3' ? 'active' : ''}`} onClick={() => handleTabClick('table3')}>Business/Premises for Sale</button>
          <button className={`mr-3 ${activeTab === 'table2' ? 'active' : ''}`} onClick={() => handleTabClick('table2')}>Buy Products</button>
          <span className={`border-highlight ${activeTab}`} />
        </div>

        {/* Render the active table based on the state */}
        <div className='mx-5 my-3'>
          {activeTab === 'table1' && (
            <div className='card-category-4 my-3 d-flex flex-wrap '>
              {
              loading ? "Loading..." :              
              <>          
              {
                data && data.length !== 0 ? data.map((item)=>(

                
              <div className="sp-card-3" key={item.id}>

              

                <ul className="product-details">
                  <li className="product-img">
                    <img src={item.image} />
                  </li>

                  <table>
                    <tr >  
                      <td  colSpan="2" >

                    <h3 className="card-title pb-0 mb-0">{item.business ? item.business : 'null'} </h3>
                      </td>
                    </tr>
                    <tr>
                      <td>  <b>Location:</b></td>
                      <td>{item.address}</td>
                    </tr>
                    <tr>
                      <td><b>Seats:</b></td>
                      <td>{item.chairs}</td>
                    </tr>
                    <tr>
                      <td><b>Time:</b></td>
                      <td>{item.availablefrom}</td>
                    </tr>
                    <tr>
                      <td><b>Name:</b></td>
                      <td>******</td>
                    </tr>
                    <tr>
                      <td><b>Email:</b></td>
                      <td>******</td>
                    </tr>
                    <tr>
                      <td><b>Contact:</b></td>
                      <td>*****</td>
                    </tr>
                    <tr>
                      <td colspan="2"><b>{item.price}/- per hour</b></td>
                    </tr>
                  </table>

                  <li className='clear'>
                    <p className='pt-2'>
                    {item.description.split(' ').slice(0, 50).join(' ')} 
                    </p>
                    <div className="d-flex">
                     <a href="https://hairwecut.co.uk/portal" target='_blank' className='submit'>
                       Show Details
                      </a>
                      </div>
                  </li>

                </ul>





              </div>
              ) 
              
              )
              :
              (
                <div>
                  No item
                </div>
              )
            }
            </>
          }
            </div>
          )}

          {activeTab === 'table2' && (
             <div className='card-category-4 d-flex flex-wrap '>
             {
               data && data.length !== 0 ? data.map((item)=>(

               
             <div className="sp-card-3" key={item.id}>
               <ul className="product-details">
                 <li className="product-img">
                   <img src={item.image} />
                 </li>

                 <table>
                 <tr >  
                      <td  colSpan="2" >

                    <h3 className="card-title pb-0 mb-0">{item.product_name ? item.product_name : 'null'} </h3>
                      </td>
                    </tr>
                   <tr>
                     <td>  <b>Brand:</b></td>
                     <td>{item.brand}</td>
                   </tr>
                   <tr>
                     <td><b>Shipment Cost:</b></td>
                     <td>{item.shift_cost}</td>
                   </tr>
                   <tr>
                     <td><b>Name:</b></td>
                     <td>******</td>
                   </tr>
                   <tr>
                     <td><b>Email:</b></td>
                     <td>******</td>
                   </tr>
                   <tr>
                     <td><b>Contact:</b></td>
                     <td>*****</td>
                   </tr>
                   <tr>
                     <td ><b>Price</b></td>
                     <td >{item.price}/-</td>
                   </tr>
                   <tr>
                     <td ><b>Discount Price</b></td>
                     <td >{item.discountprice}/-</td>
                   </tr>
                 </table>
                 <li className='clear'>
                    <p className='pt-2'>
                    {item.detail_description}   
                    </p>
                    <div className="d-flex">
                     <a href="https://hairwecut.co.uk/portal" className='submit'>
                       Show Details
                      </a>
                      </div>
                  </li>

               </ul>





             </div>
             ) 
             
             )
             :
             (
               <div>
                 No item
               </div>
             )
           }
           </div>
          )}

          {activeTab === 'table3' && (
             <div className='card-category-4 d-flex flex-wrap '>
             {
               data && data.length !== 0 ? data.map((item)=>(

               
             <div className="sp-card-3" key={item.id}>
               <ul className="product-details">
                 <li className="product-img">
                   <img src={item.image} />
                 </li>

                 <table>
                  <tr>
                  <td  colSpan="2" >

<h3 className="card-title pb-0 mb-0">{item.salon_name ? item.salon_name : 'null'} </h3>
  </td>
                  </tr>
                   <tr>
                     <td>  <b>Location:</b></td>
                     <td>{item.address}</td>
                   </tr>
                   <tr>
                     <td><b>Barbers:</b></td>
                     <td>{item.barber_available}</td>
                   </tr>
                   <tr>
                     <td><b>Name:</b></td>
                     <td>******</td>
                   </tr>
                   <tr>
                     <td><b>Email:</b></td>
                     <td>******</td>
                   </tr>
                   <tr>
                     <td><b>Contact:</b></td>
                     <td>*****</td>
                   </tr>
                   <tr>
                     <td colspan="2"><b>{item.price}/- per hour</b></td>
                   </tr>
                 </table>

                 <li className='clear'>
                    <p className='pt-2'>
                    {item.description}   
                    </p>
                    <div className="d-flex">
                     <a href="https://hairwecut.co.uk/portal" className='submit'>
                       Show Details
                      </a>
                      </div>
                  </li>

               </ul>





             </div>
             ) 
             
             )
             :
             (
               <div>
                 No item
               </div>
             )
           }
           </div>
          )}
        </div>
      </div>
      <Footer />
    </>
}
    </>
  );
};

export default MarketPlace;
