import React, { useState } from 'react';

import Axios from '../../services';

import Footer from './../Footer';
import Header from './../Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Loader from './../section/Loader';




export default function Contact() {



    const [values, setValues] = useState({ name: '', email: '',message:'',contact:''});
    const [errors, setError] = useState([]);
    const [Load,setLoad] =  useState(false);


    const handleSubmit = (e) => {
        setLoad(true);
        e.preventDefault(); // its set the default form status not refresh the page
        const data = {
            name   : values.name,
            email  : values.email,
            message: values.message,
            contact: values.contact,
        };
        Axios.post('api/contactus', data)
        .then(res => {
            setValues({ name: '', email: '',message:'' })
            setLoad(false)
            toast.dark("Request has been Submitted");
        })
        .catch(e => {
            setLoad(false)
            setError(e.response.data.Error);
        });
    }

    const handleChange = (e) => {
        setValues(values => ({ ...values, [e.target.name]: e.target.value }));
        // console.log("values:",values);
    }

    return (
        <>
        <ToastContainer />
        <Header/>
                <section className="page_header d-flex align-items-center">
                    <div className="container">
                        <div className="section_heading text-center mb-40 wow fadeInUp" data-wow-delay="300ms">
                            <h3>Hair We Cut</h3>
                            <h2>Contact Us</h2>
                            <div className="heading-line"></div>
                        </div>
                    </div>
                </section>
                <section className="contact-section padding">
                    <div className="container">
                        <div className="contact-wrap d-flex align-items-center row">
                            <div className="col-lg-6 sm-padding">
                                <div className="contact-info">
                                    <h2>Get in touch with us & <br />send us message today!</h2>
                                    <p>Our barbershop is the created for men who appreciate premium quality, time and flawless look..</p>
                                    <h3>London UK</h3>
                                    <h4><span><i class="fa fa-envelope"></i></span>: info@hairwecut.co.uk <br /></h4>
                                </div>
                            </div>
                            <div className="col-lg-6 sm-padding">
                                <div className="contact-form">
                                    <form  action="" method="post" onSubmit={handleSubmit}
                                        className="form-horizontal">
                                        <div className="form-group colum-row row">
                                            <div className="col-sm-12">
                                                <input type="text" id="name" onChange={handleChange} value={values.name} name="name" className="form-control" placeholder="Name"
                                                    required  />
                                                    <span className="text-danger">{(errors.name == '') ? '' : errors.name}</span>
                                            </div>
                                        </div>
                                        <div className="form-group colum-row row">
                                            <div className="col-sm-12">
                                                <input type="email" id="email" onChange={handleChange} value={values.email} name="email" className="form-control" placeholder="Email"
                                                    required />
                                                    <span className="text-danger">{(errors.email == '') ? '' : errors.email}</span>
                                            </div>
                                        </div>
                                        <div className="form-group colum-row row">
                                            <div className="col-sm-12">
                                                <input type="text" id="name" onChange={handleChange} value={values.contact} name="contact" className="form-control" placeholder="Contact #"/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                            <span className="text-danger">{(errors.message == '') ? '' : errors.message}</span>
                                                <textarea id="message" onChange={handleChange} value={values.message} name="message" cols="30" rows="5"
                                                    className="form-control message" placeholder="Message" required ></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <button id="submit" className="default_btn" type="submit">Send Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        <Footer/>
       </>
    )
}