import React, { Component } from "react";

import { Link } from "react-router-dom";
import Loader from './../section/Loader';

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Footer from './../Footer';
import Header from './../Header';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: "",
      Load: false,
      toster: false,
    };
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  

  onSubmit = async e => {
    e.preventDefault();

    this.setState({ Load: true });

    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    const resp = await this.props.loginUser(userData);
    if (resp.success) {
      // redirect
      this.setState({ Load: false });
      this.props.history.push("/dashboard");

    } else {

      this.setState({ errors: "Email or Password Is In Correct", Load: false });
    }
  };


  render() {

    const { errors } = this.state;


    return (
      <>
        <ToastContainer />
        {(this.state.Load) ?
          <Loader />
          : <>
            <Header />
            <section className="page_header d-flex align-items-center" style={{ padding: "100px", height: "auto" }}>
              <div className="container">
                <div className="section_heading text-center mb-40 wow fadeInUp" data-wow-delay="300ms">
                  <div className="row">
                    <div className="col-md-4 offset-md-4">
                      <form action="" noValidate onSubmit={this.onSubmit} className="form-horizontal appointment_form" style={{ background: "rgba(0,0,0,0.7)" }}>
                        <div className="book_content">
                          <h2>Sign-In</h2>
                        </div>

                        <div className="form-group row">
                          <div className="col-md-12 padding-10">
                            <input
                              onChange={this.onChange}
                              value={this.state.email}
                              error={errors.email}
                              id="email"
                              type="email"
                              placeholder="E-Mail Address"
                              className={classnames("form-control", {
                                invalid: errors.email || errors.emailnotfound
                              })}
                            />
                          </div>
                        </div>

                        <div className="form-group row">

                          <div className="col-md-12 padding-10">
                            <input
                              onChange={this.onChange}
                              value={this.state.password}
                              error={errors.password}
                              id="password"
                              type="password"
                              placeholder="Password **** "
                              className={classnames("form-control", {
                                invalid: errors.password || errors.passwordincorrect
                              })}
                            />
                            <span className="text-danger">{this.state.errors}</span>

                          </div>
                        </div>
                        <button className="default_btn btn-block" type="submit">SignIn</button>
                        <Link to="/signup" className="default_btn btn-block" style={{ backgroundColor: "transparent", border: "1px solid #9e8a78" }}>Signup</Link>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
            <style jsx>{`
        @media screen and (max-width: 1000px) {
          .page_header {
            padding: 100px 10px !important;
          }
        }
      `}</style>
          </>
        }
      </>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(Login);
// Terms