import { ADD_TO_CART, REMOVE_ITEM, SUB_QUANTITY, ADD_QUANTITY, ORDER_PLACED } from '../actions/types';



const initState = {
    addedItems: [],
    total: 0,
    totalProduct: 0,

}
export const cartReducer = (state = initState, action) => {


    if (action.type === ADD_TO_CART) {
        let existed_item = state.addedItems.find(item => action.payload.id === item.id);

        if (existed_item) {

            existed_item.quantity += 1;
            const totalItemsAdded = state.addedItems.reduce(
                (accumulator, item) => accumulator + Number(item.quantity),
                0,
            );

            return {
                ...state,
                total: state.total + existed_item.price,
                totalProduct: totalItemsAdded,
            }

        } else {

            action.payload.quantity = 1;
            let price = 0;
            if(action.payload.dprice == 0){
                 price = action.payload.sale_price;
            }else{
                 price = action.payload.dprice;
            }
            let newTotal = state.total + price;

            return {
                ...state,
                addedItems: [...state.addedItems, action.payload],
                total: newTotal,
                totalProduct: state.totalProduct + action.payload.quantity,
            }
        }
    }

    if (action.type === REMOVE_ITEM) {

        let itemToBeRemoved = state.addedItems.find(item => action.payload.id === item.id);
        let new_items = state.addedItems.filter(item => action.payload.id !== item.id);

        let newTotal = state.total - (itemToBeRemoved.price * itemToBeRemoved.quantity);
        const totalItemsAdded = state.addedItems.reduce(
            (accumulator, item) => accumulator + Number(item.quantity),
            0,
        );
        return {
            ...state,
            addedItems: new_items,
            total: newTotal,
            totalProduct: totalItemsAdded,
        }
    }

    if (action.type == ADD_QUANTITY) {
        let item = state.addedItems.find(item => item.id === action.payload.id);
        item.quantity += 1;
        let newTotal = state.total + item.price;
        return {
            ...state,
            total: newTotal,
            totalProduct: state.totalProduct + 1,
        }
    }

    if (action.type === SUB_QUANTITY) {
        // if the quantity is equal to 1 then remove the item from cart
        let item = state.addedItems.find(item => item.id === action.payload.id);
        if (item.quantity === 1) {
            //remove it
            let newItems = state.addedItems.filter(item => item.id !== action.payload.id);
            let newTotal = state.total - item.price;
            return {
                ...state,
                addedItems: newItems,
                total: newTotal,
                totalProduct: state.totalProduct - 1,
            }
        } else {
            let newTotal = state.total - item.price;
            item.quantity -= 1;
            return {
                ...state,
                total: newTotal,
                totalProduct: state.totalProduct - 1,
            }
        }

    }

    if (action.type === ORDER_PLACED) {

        return {
            ...state,
            addedItems: [],
            total: 0,
            totalProduct: 0,
        }


    }

    return state;
}
export default cartReducer;