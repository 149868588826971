import React from 'react';
import Footer from './../Footer';
import Header from './../Header';

// Section
import Making from './../section/MakingSection';
import Brands from './../section/Brands';

export default function Services() {

    return (
        <>
        <Header/>
                
            <section className="page_header d-flex align-items-center">
                <div className="container">
                    <div className="section_heading text-center mb-40 wow fadeInUp" data-wow-delay="300ms">
                        <h3>Hair We Cut</h3>
                        <h2>Barbershop Services</h2>
                        <div className="heading-line"></div>
                    </div>
                </div>
            </section>

            <section className="service_section bg-grey padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 padding-15 wow fadeInUp" data-wow-delay="200ms">
                            <div className="service_box">
                                <i className="bs bs-scissors-1"></i>
                                <h3>Haircut Styles</h3>
                                <p>Barber is a person whose occupation is mainly to cut dress style.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 padding-15 wow fadeInUp" data-wow-delay="300ms">
                            <div className="service_box">
                                <i className="bs bs-razor-2"></i>
                                <h3>Beard Triming</h3>
                                <p>Barber is a person whose occupation is mainly to cut dress style.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 padding-15 wow fadeInUp" data-wow-delay="400ms">
                            <div className="service_box">
                                <i className="bs bs-brush"></i>
                                <h3>Smooth Shave</h3>
                                <p>Barber is a person whose occupation is mainly to cut dress style.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 padding-15 wow fadeInUp" data-wow-delay="500ms">
                            <div className="service_box">
                                <i className="bs bs-hairbrush-1"></i>
                                <h3>Face Masking</h3>
                                <p>Barber is a person whose occupation is mainly to cut dress style.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 padding-15 wow fadeInUp" data-wow-delay="600ms">
                            <div className="service_box">
                                <i className="bs bs-razor"></i>
                                <h3>Beard Triming</h3>
                                <p>Barber is a person whose occupation is mainly to cut dress style.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 padding-15 wow fadeInUp" data-wow-delay="700ms">
                            <div className="service_box">
                                <i className="bs bs-hairbrush"></i>
                                <h3>Hair Coloring</h3>
                                <p>Barber is a person whose occupation is mainly to cut dress style.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 padding-15 wow fadeInUp" data-wow-delay="800ms">
                            <div className="service_box">
                                <i className="bs bs-comb-2"></i>
                                <h3>Hair Straight</h3>
                                <p>Barber is a person whose occupation is mainly to cut dress style.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 padding-15 wow fadeInUp" data-wow-delay="900ms">
                            <div className="service_box">
                                <i className="bs bs-perfume"></i>
                                <h3>Bright Facial</h3>
                                <p>Barber is a person whose occupation is mainly to cut dress style.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Making />
            <Brands />

        <Footer/>
       </>
    )
}