import React,{useState} from 'react';
import './../assets/css/marker.css';
import icon from './../assets/img/icon-4.png';
import { Link } from "react-router-dom";

const Marker = (props) => {

    const [details, setdetails] = useState(false)

    const ChangeHandle = () => {
        setdetails(!details)
    }
    return (
        <>
        {/* {console.log("props",props.data)} */}
        <div>
            <div
            className="pin bounce">
                <img src={icon}  onMouseOver={ChangeHandle}  className='img-fluid w-100'   />
                {(details)? 

                <div className="row overlay3" onMouseLeave={ChangeHandle}>
                    <div className="col-md-12">
                            <h4 className="mb-0 mt-0 text-uppercase">{props.data.name}</h4>
                            <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats" >
                                <div className="d-flex flex-column"> <span className="articles">Bookings</span> <span className="number1">{props.data.booking}</span> </div>
                                <div className="d-flex flex-column"> <span className="rating">Rating</span> <span className="number3">{parseFloat(props.data.rating).toFixed(1)}</span> </div>
                                <div className="d-flex flex-column"> <Link to={'/getbarbers/'+props.data.id}><a href="" className="btn btn-sm ml-2" style={{background:"#9e8a78",color:"#fff",fontSize:"10px"}}>Make Appointment</a></Link> </div>
                            </div>
                            <div className="button mt-2 ">
                                
                            </div>
                    </div>
                </div>
                : '' }
            </div>

            <div className="pulse" />
        </div>
    </>
    );
  };

  export default Marker;