import React from 'react'
import arrow from './../assets/img/arrow.png';
import arrow2 from './../assets/img/arrow_2.png';

const OurVision = () => {
  return (
    <>
        <section id="about" className="about_section  padding">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-6">
                            <div className="about_content align-center">
                                <h3 className="wow fadeInUp" data-wow-delay="100ms"></h3>
                                <h2 className="wow fadeInUp" data-wow-delay="200ms">Our Vision</h2>
                                {/* <img className="wow fadeInUp" data-wow-delay="500ms" src={Logo} alt="logo" width="150" /> */}
                                <p className="wow fadeInUp text-justify" data-wow-delay="600ms">We envision a world where style and convenience coexist harmoniously. A world where finding the perfect grooming service or product is not just easy, but a delightful experience. At Hair We Cut, we're making this vision a reality by bridging the gap between traditional barbering and modern-day demands.</p>
                                {/* <a href="" className="default_btn wow fadeInUp" data-wow-delay="600ms">More About Us</a> */}
                            </div>
                        </div>
                        <div className="col-md-6 d-none d-md-block">
                            <div className="about_content align-center wow fadeInUp" data-wow-delay="800ms">
                              <img src={arrow} className='img-fluid w-50' alt="" srcset="" />
                              </div>
                        </div>
                        <div className="col-md-6 d-none d-md-block">
                            <div className="about_content align-center wow fadeInUp" data-wow-delay="800ms">
                              <img src={arrow2} className='img-fluid w-50' alt="" srcset="" />
                              </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="about_content align-center ">
                                <h3 className="wow fadeInUp" data-wow-delay="100ms"></h3>
                                <h2 className="wow fadeInUp" data-wow-delay="200ms">Our Services</h2>
                                {/* <img className="wow fadeInUp" data-wow-delay="500ms" src={Logo} alt="logo" width="150" /> */}
                                <p className="wow fadeInUp text-justify" data-wow-delay="600ms">From the comfort of your home to the heart of bustling cities, our mobile barbering service ensures that you're never far from achieving your ideal look. But that's just the beginning. Our platform serves as a vibrant marketplace, offering everything from exclusive grooming products to salon essentials, crafted and curated for the discerning individual.</p>
                                {/* <a href="" className="default_btn wow fadeInUp" data-wow-delay="600ms">More About Us</a> */}
                            </div>
                        </div>
                        
                        <div className="col-md-6">
                            <div className="about_content align-center">
                                <h3 className="wow fadeInUp" data-wow-delay="100ms"></h3>
                                <h2 className="wow fadeInUp" data-wow-delay="200ms">Community at Our Core</h2>
                                {/* <img className="wow fadeInUp" data-wow-delay="500ms" src={Logo} alt="logo" width="150" /> */}
                                <p className="wow fadeInUp text-justify" data-wow-delay="600ms">At the heart of Hair We Cut is our community—a network of passionate individuals, clients, and professionals bound by a love for exceptional grooming. We're more than a platform; we're a movement towards a more connected, stylish, and empowered grooming world.</p>
                                {/* <a href="" className="default_btn wow fadeInUp" data-wow-delay="600ms">More About Us</a> */}
                            </div>
                        </div>
                        <div className="col-md-6 d-none d-md-block">
                            <div className="about_content align-center wow fadeInUp" data-wow-delay="800ms">
                              <img src={arrow} className='img-fluid w-50' alt="" srcset="" />
                              </div>
                        </div>
                        <div className="col-md-6 d-none d-md-block">
                            <div className="about_content align-center">
                              <img src={arrow2} className='img-fluid w-50' alt="" srcset="" />
                              </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="about_content align-center wow fadeInUp" data-wow-delay="800ms">
                                <h3 className="wow fadeInUp" data-wow-delay="100ms"></h3>
                                <h2 className="wow fadeInUp" data-wow-delay="200ms">Empowering Professionals</h2>
                                {/* <img className="wow fadeInUp" data-wow-delay="500ms" src={Logo} alt="logo" width="150" /> */}
                                <p className="wow fadeInUp text-justify" data-wow-delay="600ms">We believe in growth and opportunity for all. Hair We Cut is a haven for professionals looking to expand their horizons, be it through renting space, sharing expertise, or exploring new career paths within our dedicated job portal.</p>
                                {/* <a href="" className="default_btn wow fadeInUp" data-wow-delay="600ms">More About Us</a> */}
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="about_content align-center ">
                                <h3 className="wow fadeInUp" data-wow-delay="100ms"></h3>
                                <h2 className="wow fadeInUp" data-wow-delay="200ms">Join Us
</h2>
                                {/* <img className="wow fadeInUp" data-wow-delay="500ms" src={Logo} alt="logo" width="150" /> */}
                                <p className="wow fadeInUp text-justify" data-wow-delay="600ms">Embark on a journey with Hair We Cut and be part of a grooming revolution. Whether you're seeking the ultimate grooming experience, looking to grow your professional footprint, or wanting to connect with like-minded individuals, your path to excellence starts here.</p>
                                {/* <a href="" className="default_btn wow fadeInUp" data-wow-delay="600ms">More About Us</a> */}
                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>
    </>
  )
}

export default OurVision