import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { Link,useHistory } from "react-router-dom";
import Axios from '../../services';
import { orderPlace } from '../../actions/CartAction';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './../section/Loader';



async function stripeTokenHandler(token, data) {
  const paymentData = { token: token.id, data }; 

   const ax =  Axios.post('api/customer/productPayment',paymentData)
      .then(res => {
           return res.data || { success: false }
      })
      return ax;
}



const CheckoutForm = (props) => {


  const [payment, setPayment] = useState(false)
  const [Load, setLoad] = useState(false)
  const history = useHistory()

  const  orderplace = (pro) => {
    dispatch(orderPlace());   
 }

  const dispatch = useDispatch()

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    // setLoad(true)
    const { stripe, elements, data } = props;
    console.log(props,'props');

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);

    const result = await stripe.createToken(card);
    console.log(card,'card',result,'result');

    if (result.error) {
      // Show error to your customer.
      console.log("Erroe:",result.error.message);
    } else {

      stripeTokenHandler(result.token, data).then(data => {
        console.log('stripeTokenHandler',data);
        if (data.success) {
          console.log('success');
          setLoad(false)
          setPayment(true)
          orderplace()
          history.push("/success")
        }
      })
    }

  };


  return (
    <>
      {(Load) ?
        <Loader />
        : ''
      }

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input name="amount" type="text" className="form-control" value={props.data.alldata.amount} readOnly />
            </div>
            <div className="form-control">
              <CardElement />

            </div>
            <button type="submit" className="btn btn-block" style={{background:"#9e8a78",color:"#fff",borderRadius:"0px"}} disabled={!props.stripe}>Pay</button>
          </form>
    </>

  );
  // }
}




export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CheckoutForm elements={elements} stripe={stripe} data={props} />
      )}
    </ElementsConsumer>
  );
}