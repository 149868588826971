import { useEffect, useState } from "react";

function CurrentLocation() {


    const [location,setLocation] = useState({
        
        loaded:false,
        lat:'',
        lng:''
    })
     
    const onSuccess = (location)  => {

        setLocation({

            loaded:true,
            lat:location.coords.latitude,
            lng:location.coords.longitude,
        });

    };

    const onError = (error) =>{
        setLocation({
            loaded:true,
           error,
        });

    };

    useEffect(() => {

        if(!("geolocation" in navigator) ){
            onError ({
                code:0,
                message:"Locatin Not Supported",
            });
        }
        navigator.geolocation.getCurrentPosition(onSuccess,onError);

    },[]);

    return location;
}

export default CurrentLocation
