import React, {useState,useEffect} from 'react'
import Footer from './../Footer';
import Header from './../Header';
import Loader from './../section/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Axios from '../../services';


import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import MyCheckoutForm from './Paymentdetails'
const stripePromise = loadStripe("pk_test_51IUEm1Gp0YSIUkCJCxvVuSaBViQfDWT3INQs2e0gYApP1rPRMOSGkr2UCpn7zsGxdA408qHUfNP4ulin6ccVvT9V00xKOUJaS7");


function Payment(props) {

  const [appointment, setAppoinment] = useState()
  const [Load,setLoad] =  useState(false);

    useEffect(() => {
        fetchData();
        return ()=> {
            fetchData();
        }
    },[Load]);

  const  fetchData =() =>{
      Axios.get('api/customer/serviceamount/'+localStorage.getItem('appid'))
        .then(res => {
          setAppoinment(res.data.amount)
          console.log(res.data.amount,'appointment amout')
          setLoad(false)
        })
    }

  return (
    <>
      {(Load) ?
            <Loader />
            :''
      }
      <ToastContainer />
       <Header/>

      <section className="page_header d-flex align-items-center" style={{padding:"200px",height:"auto"}}>
          <div className="container">
              <div className="section_heading text-center mb-40 wow fadeInUp" data-wow-delay="300ms" >
                  <div className="row"> 
                      <div className="col-md-4 offset-md-4">
                        <Elements stripe={stripePromise}>
                          <MyCheckoutForm id={localStorage.getItem('appid')} amount={appointment && appointment} />
                        </Elements>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <Footer/>
      <style jsx>{`
        @media screen and (max-width: 900px) {
          .page_header {
            padding: 100px 50px !important;
          }
        }
      `}</style>

              
      
    </>
  )
}

export default Payment
