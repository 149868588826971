import React from 'react';

export default function MakingSection() {

    return (
        <>
            <section className="cta_section padding">
                <div className="container">
                    <div className="display-table">
                        <div className="table-cel">
                            <div className="cta_content align-center wow fadeInUp" data-wow-delay="300ms">
                                <h2>Making You Look Good <br /> Is In Our Haritage.</h2>
                                <p>Barber is a person whose occupation is mainly to cut dress groom <br />style and shave men's
                                    and boys hair.</p>
                                <a href="#" className="default_btn">Make Appointment</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          
        </>
    )
}