
import { ADD_TO_CART,REMOVE_ITEM,SUB_QUANTITY,ADD_QUANTITY,ADD_SHIPPING,ORDER_PLACED} from './types'

//add cart action
export const addToCart= (product)=>{
    return{
        type: ADD_TO_CART,
        payload:product
    }
}
//remove item action
export const removeItem=(product)=>{
    return{
        type: REMOVE_ITEM,
        payload:product
    }
}
//subtract qt action
export const subtractQuantity=(product)=>{
    return{
        type: SUB_QUANTITY,
        payload:product
    }
}
//add qt action
export const addQuantity=(product)=>{
    return{
        type: ADD_QUANTITY,
        payload:product
    }
}

export const orderPlace = ()=>{
    return{
        type: ORDER_PLACED,
    }
}
