import React, { useState } from 'react'

import Footer from './../Footer';
import Header from './../Header';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import MyCheckoutForm from './ProductPaymentdetails';
const stripePromise = loadStripe("pk_test_51IUEm1Gp0YSIUkCJCxvVuSaBViQfDWT3INQs2e0gYApP1rPRMOSGkr2UCpn7zsGxdA408qHUfNP4ulin6ccVvT9V00xKOUJaS7");


// Products


function CheckOut() {

    const CartProducts = useSelector((state) => state.cart);
    const ids = [];
    const qty = [];
    const prices = [];

    const [values, setValues] = useState({ contact: '', location: '', type: 'barber' });

    CartProducts.addedItems.map((pro) => (
        ids.push(pro.id)
    ))
    CartProducts.addedItems.map((pro) => (
        qty.push(pro.quantity)
    ))
    CartProducts.addedItems.map((pro) => (
        (pro.dprice == 0) ?
            prices.push(pro.sale_price)
        :
            prices.push(pro.dprice)
    ))

    const handleChange = (e) => {
        setValues(values => ({ ...values, [e.target.name]: e.target.value }));
    }
    return (
        <>
            <ToastContainer />
            <Header />

            <section id="team" className="team_section bd-bottom padding">
                <div className="container">
                    <div className="section_heading text-center mb-40 mt-40 wow fadeInUp" data-wow-delay="300ms">
                        <h3>Hair We Cut</h3>
                        <h2>Check Out</h2>
                        <div className="heading-line"></div>
                    </div>

                    <div className="row  ">
                        <div className="col-md-9">

                            <div className="row">

                                <div className="col-md-6 offset-3">
                                    <div className="form-group">
                                        <select className="form-control" onChange={handleChange} value={values.type} name="type">
                                            <option value="barber" selected>Collect From Barber</option>
                                            <option value="address">Deliver To Address</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            {(values.type == 'barber') ? '' :
                                <div>
                                    <div className="row">
                                        <div className="col-md-6 offset-3">
                                            <div className="form-group">
                                                <input type="text" placeholder="Post Code / Location" onChange={handleChange} value={values.location} name="location" className="form-control" />
                                            </div>
                                        </div>

                                    </div>


                                    <div className="row">
                                        <div className="col-md-6 offset-3">
                                            <div className="form-group">
                                                <input type="text" placeholder="Contact #" onChange={handleChange} value={values.contact} name="contact" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-md-6 offset-3">
                                    <Elements stripe={stripePromise}>
                                        <MyCheckoutForm alldata={{ amount: CartProducts.total, ids: ids, qty: qty, price: prices, type: values.type, address: values.location, contact: values.contact }} />
                                    </Elements>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-3 xs-padding">
                            <div className="widget-content">
                                <h4>Cart Products</h4>
                                <ul className="thumb-post">
                                    {CartProducts.addedItems.map((product, i) => (

                                        <li key={i}>
                                            <img src={'https://hairwecut.co.uk/portal/products/' + product.img} className="img-fluid" alt="img" />
                                            {/* <img src={'http://127.0.0.1:8000/products/' + product.img} className="img-fluid" alt="img"  /> */}
                                            <a style={{ fontSize: "12px" }}>
                                                {product.product_name}
                                                <br />
                                                Price: {(product.dprice == 0) ? product.sale_price : product.dprice}
                                                <br />
                                                Qty: {product.quantity}

                                            </a>
                                        </li>

                                    ))}


                                </ul>
                                <hr />

                                <ul className="thumb-post">
                                    <li>
                                        <a>
                                            Total : {CartProducts.total}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Products ./ */}
                </div>
            </section>


            <Footer />
        </>
    )
}

export default CheckOut
