import Axios from "./../services";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from "./types";

// Register User
export const registerUser = (userData) => dispatch => {
  const resp = Axios
    .post("api/customer/register", userData)
    .then(res => { 
        
        return { ...res, redirect: true } }
      )
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
      return { redirect: false }
    }
    );
  return resp;
};

// Login - get user token
export const loginUser = userData => dispatch => {
  try {
    const postedResponse = Axios
      .post("api/customer/login", userData)
      .then(res => {
        // Save to localStorage

        // Set token to localStorage
        const { token } = res.data;
        // console.log(token);
        localStorage.setItem("jwtToken", token);
        // Set token to Auth header
        setAuthToken(token);
        // Decode token to get user data
        const decoded = jwt_decode(token);
        // Set current user
        dispatch(setCurrentUser(decoded));
        return { ...res, success: true }
      })
      .catch(
        err => {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          })
          return { success: false }
        }
      );
    return postedResponse
  } catch (e) {
    return { success: false }
  }
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};