import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';


// CSS Links
import './assets/css/elegant-font-icons.css';
import './assets/css/themify-icons.css';
import './assets/css/barber-icons.css';
import './assets/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/css/venobox/venobox.css';
import './assets/css/nice-select.css';
import './assets/css/slicknav.min.css';
import './assets/css/main.css';
import './assets/css/responsive.css';

import PrivateRoute from "./private-route/PrivateRoute";
// ./ CSS liks

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import Services from './pages/Services';
// import Appointment from './pages/Appointment';
import BarberDetails from './pages/BarberDetails';
import BecomeaPartner from './pages/BecomeaPartner';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import Products from './pages/Products';
import Payment from './pages/Payment';
import Cart from './pages/Cart';
import CheckOut from './pages/CheckOut';
import Review from './pages/Review'
import Success from './pages/Success'
import TermsAndCondition from './pages/TermsAndCondition'
// Barber Map
import Appointment from './pages/BarberMap'
import GetBarbers from './pages/GetBarbers'
// import Test from './pages/Test';


import Dashboard from './dashboard/Dashboard';



import jwt_decode from "jwt-decode";
import setAuthToken from "../utils/setAuthToken";

import { setCurrentUser, logoutUser } from "../actions/authActions";
import { Provider } from "react-redux";
import store from "../store";
import Jobs from './pages/Jobs';
import MarketPlace from './pages/MarketPlace';

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    // store.dispatch(logoutUser());
    localStorage.clear();
    // Redirect to login
    window.location.href = "./signin";
  }
}

function App() {
  return (
    <>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} exact={true} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/product" component={Products} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/becomeapartner" component={BecomeaPartner} />
            <Route exact path="/jobs" component={Jobs} />
            <Route exact path="/marketplace" component={MarketPlace} />
            <Route exact path="/cart" component={Cart} />
            <Route exact path="/review/:id" component={Review} />
            <Route exact path="/success" component={Success} />
            <Route exact path="/terms" component={TermsAndCondition} />

            <Route exact path="/signin" component={Signin} />

            <Route exact path="/signup" component={Signup} />

            {/* Dashboard Routes */}
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/appointment" component={Appointment} />
            <PrivateRoute exact path="/barberdetails/:id" component={BarberDetails} />
            <PrivateRoute exact path="/getbarbers/:id" component={GetBarbers} />
            <PrivateRoute exact path="/payment" component={Payment} />
            <PrivateRoute exact path="/checkout" component={CheckOut} />


          </Switch>
        </Router>
      </Provider>
    </>
  );
}

export default App;
