import { Header } from '../Header'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Axios from '../../services';
import { useEffect, useState } from 'react'
import Footer from '../Footer';

const GetBarbers = () => {
  const { id } = useParams();
  const auth = useSelector(state => state.auth);
  const [data, SetData] = useState();
  const fetchData = () => {
    Axios.get(`api/customer/barberagainstbusiness/${id}`)
      .then(res => {
        // console.warn(res.data.barbers,'data');
        SetData(res.data.barbers);
      });
  }
  useEffect(() => {
    fetchData();


  }, [])

  return (
    <div>
      <Header auth={auth} />
      <section id="team" class="team_section bd-bottom padding" style={{ marginTop: "70px" }} >
        <div class="container"><div class="section_heading text-center mb-40 wow fadeInUp" data-wow-delay="300ms">
          <h3>Hair We Cut</h3>
          <h2>Our Barbers</h2>
          <div class="heading-line">
          </div>
        </div>
          <ul class="team_members row ">
            {
              data && data.map((data) => (
               
                <li className='mx-2'>
                  <div id="card" key={data.id}>
                    <img src={`https://hairwecut.co.uk/portal/barberDoc/${data.img}`} />
                    <i class="ri-heart-fill"></i>
                    <h1 className='text-dark'>{data.name}</h1>
                    {/* <p>{data.location}</p> */}
                    <a id="book" href={`/barberdetails/${data.id}`}>
                    Book Appointment
                    </a>
                  </div>

                </li>
              ))
            }
          </ul></div>
      </section>
      <Footer/>
    </div>
  )
}

export default GetBarbers