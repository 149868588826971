import React from 'react'
import Footer from './../Footer';
import Header from './../Header';

function TermsAndCondition() {
    return (
        <>
        <Header />
        <section className="page_header d-flex align-items-center">
            <div className="container">
                <div className="section_heading text-center mb-40 wow fadeInUp" data-wow-delay="300ms">
                    <h2>Terms & Conditions</h2>
                    <div className="heading-line"></div>
                </div>
            </div>
        </section>

        <section className="about_section bd-bottom padding">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-10 offset-1 text-justify xs-padding wow fadeInLeft" data-wow-delay="300ms">
                        <div className="section_heading">
                            <p>Kindly read the following terms and conditions carefully before booking your appointment. We have our own policies of using websites and Apps; both customers and barbers must agree with these terms and conditions before their agreement. Our terms and conditions are as follows,</p>
                            <p>1. Those users who are using our website and application services are required to provide authentic information which includes their user name, ID, contact, address etc.</p>
                            <p>2.  We have smart database systems for data securing, we keep your data secure and do not share it with any other third party.</p>
                            <p>3.  Our policies may get change for the users with time; therefore, it is required for both customers and users to read the policies on regular basis.</p>
                            <p>4. Those users who are not interested in our policies can delete their account from our websites and applications; they will not be able to get our services anymore.</p>
                            <p>5.We also, keep in touch with our customers through our email support system, if you have any kind of query or any legal issues then you can also email us and get a response from our customer support team. Our services will be available from Monday – Friday and closed on Saturday and Sunday (holidays).</p>
                            <p>6. If we have found a negative response or false attitude from our customers then we have the right to cancel your account from our websites and apps.</p>

                            <h3 style={{backgroundColor:"rgba(158,138,119,1)",padding:"10px",color:"#fff"}}>Personal Data Security</h3>
                            <p>1.      The customers and barbers are required to provide their username and authentic password on our official website and application. It is also important for both customers and barbers to enter the password which was not used in any other account.</p>
                            <p>2.      If the users want to delete or deactivate their profile then they are also allowed to delete their profile from our website.</p>


                            <h3 style={{backgroundColor:"rgba(158,138,119,1)",padding:"10px",color:"#fff"}}>General rules for web and application users</h3>
                            <p>1.      The customers and barbers of HAIR WE CUT should read the basic terms and condition and understand the business policies.</p>
                            <p>2.      It is necessary for the users to accept the terms & conditions before making their appointment; it will help them to understand the booking details of their services.</p>
                            <p>3.       Our professional barbers will provide their services to customers after their confirmation message on the website application.</p>
                            <p>4.      If the users want to delete or deactivate their profile then they are also allowed to delete their profile from our website.</p>
                            <p>5.      After the account setting and accessibility, it is to be ensured that you are agreeing with our terms and conditions and avail the services of HAIRWECUT as per their business rules.</p>
                            <p>6.      In case of any kind of uncertainty, we reserve a right to cancel the account of fake users from our website and applications. </p>

                            <h3 style={{backgroundColor:"rgba(158,138,119,1)",padding:"10px",color:"#fff"}}>Users responsibilities</h3>
                            <p>1.      The customers and the barbers are advised to co-operate with us, it helps us to improve the further growth of the business.</p>
                            <p>2.      The customers should share their feedback on our official website and also share their views regarding the services provided by the barbers.</p>
                            <p>3.      The Users are advised not to share their personal information with any third party. In case of any fraud, HAIRWECUT will not be responsible for it.</p>
                            <p>4.      The barbers are also responsible to provide desired services to their customers and provide them a unique style in a comfortable zone.</p>
                            <p>5.      In HAIR WE CUT, we also believe that the co-operation of the customers are also important with our barbers, they should efficiently appreciate them; whereas, if they have found any kind of the negative situation then they are also allowed to contact the management and register their query.</p>
                            <p>6.      During the services, if the customer loses their necessary items such as desktop, cell phone, etc then the HAIR WE CUT are not responsible for the loss. It is the duty of the customer to take care of their items during their service hours.</p>


                            <h3 style={{backgroundColor:"rgba(158,138,119,1)",padding:"10px",color:"#fff"}}>Terms & conditions for barbers</h3>
                            <p>
                                •    The barbers are required to provide their services on time; otherwise, the customer will deduct their late charges.
                            </p>
                            <p>•         All service products and accessories of the barbers should be clean and well- hygienic before the starting of their work. The barbers must provide their neat and clean services in the shops and also in homes provided by their customers through application service. The customer will rate the barbers based on the quality of their work.</p>
                            <p>•         The barbers should discuss with their clients their services and also provide them a brief knowledge of the products using for the hair and skin treatments. The barbers should be kind with their customers and also deliver positive attitude and behavior to their customers</p>
                            <p>•         In case of any emergency, if the barbers will not be able to reach their location on time then they can also cancel their appointment from the online application and also mention their reason so that the client would be able to book another appointment from another barber.</p>


                            <h3 style={{backgroundColor:"rgba(158,138,119,1)",padding:"10px",color:"#fff"}}>Terms & conditions for customers</h3>
                            <p>•         The customers are required to take care of their personal items such as handbags, mobile phone and other accessories; in case of any loss, the barbers and the HAIR WE CUT team will not be responsible.</p>
                            <p>•         If the barbers are late then the customers are allowed to charge them as per the rules of the business.</p>
                            <p>•         The customers are also advised to come on time at the shop; if they are 10 mins late then they will be partially charged by HAIR WE CUT.</p>
                            <p>•         In case of any emergency, if the customers have canceled their booking then the HAIR WE CUT reserves the right to return their payment as per the policy of their business.</p>
                            <p>•         In case of any unfavorable situation, the customers can also register their complaint on our official website www.hairwecut.co.uk.</p>

                            <h3 style={{backgroundColor:"rgba(158,138,119,1)",padding:"10px",color:"#fff"}}>Our responsibility</h3>
                            <p>•         We want to inform our users and customers that our website and apps can only be use for the private and domestic purpose, you are not allowed to use our website and apps for business use.</p>
                            <p>•         It is to be informed that in case of any loss in business, profit, and any other kind of business development, we are not responsible and does not take any guarantee for it.</p>
                            <p>•         In case of any kind of software failure and virus detection we are also not responsible and do not take guarantee that the websites are error-free. If you have found any kind of hacking and service attack on your computer system then the HAIRWECUT is not responsible for your loss.</p>
                            <p>•         Our terms and conditions may get change from time to time as per the requirement of the users; therefore, it is to be informed that the customers and barbers should read the term and conditions criteria before making their appointment.</p>


                            <h3 style={{backgroundColor:"rgba(158,138,119,1)",padding:"10px",color:"#fff"}}>Our serviced countries</h3>
                            <p>We are providing our services to the users of the UK only. Our website and apps will not be accessible outside of the UK; therefore, the users must check the complete details of our services before their appointment.</p>


                            <h3 style={{backgroundColor:"rgba(158,138,119,1)",padding:"10px",color:"#fff"}}>Return policy for the organic products</h3>
                            <p>As we are also selling organic products in our barbershops such as hair oil, hair creams, face wash, face mask. We are also taking a guarantee of our products because they are pure and do not contain any chemical ingredients. If the customers want to buy these products then they can also get these amazing products from our shop at reasonable prices. If the customers are not satisfied with our products and want to return these products then they can return them within 2 days. As per our policy, we will not take the products after 2 days and the amount will also be non-refundable.</p>



                            <h3 style={{backgroundColor:"rgba(158,138,119,1)",padding:"10px",color:"#fff"}}>Appointment criteria</h3>
                            <p>•         As we are delivering our services at home and barbershop of HAIRWECUT. It is to be informed to all the customers and barbers to provide their comfortable time schedule before making their appointment.</p>
                            <p>•         You can select between shop barbers and mobile barbers on our appointment portal. For this purpose, the customers are required to provide their name, email, address, phone no, and the other mentioned requirements.</p>
                            <p>•          If the customers are interested in availing services at our barbershops then they are advised to visit our salon before an hour of their appointment. </p>
                            <p>•         If the customers and barbers are late then they have to pay late charges as per our business rules; otherwise, we will not be able to re-book their new appointment.</p>
                            <p>•         In case of any emergency, the barbers and customers both can cancel their appointment within one hour; otherwise, your amount will not be refundable.</p>
                            <p> •         The customers can also reschedule the date and time of their appointment after the confirmation message of their previous appointment; however, they are advised to inform their barbers within an hour; otherwise, they will be charged by their assigned barbers.</p>
                            <p>•         After receiving the confirmation message of the appointment from our customer support, the customers and barbers are requested to acknowledge the email of the HAIRWECUT as soon as possible.</p>
                            <p>•         If you are interested to cancel the appointment of mobile barber and want to book shop barber then you are requested to cancel the appointment immediately and request for the service of shop barber.</p>
                            <p>•         As per our policy, the amount will only be refundable to the customers and barbers if and only they have canceled their appointment within one hour of the appointment; otherwise, they have to pay the penalty for it.</p>
                            


                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
            
        </>
    )
}

export default TermsAndCondition
