import React, { useEffect } from 'react';
import Footer from './../Footer';
import Header from './../Header';

// Section
import Vedio from './../section/Vedio';
import HomeAbout from './../section/HomeAbout';
import Service from './../section/Service';
import Price from './../section/Price';
import OurBarbers from './../section/OurBarbers';
import MakingSection from './../section/MakingSection';
import News from './../section/News';
import Review from './../section/Review';
import Brands from './../section/Brands';
import OurVision from '../section/OurVision';

export default function Home() {
    useEffect(() => {
        const wow = new window.WOW();
        wow.init();
    }, []);

    return (
        <>
        <Header/>
            <Vedio />
            <HomeAbout />
            <OurVision />
            {/* <Service /> */}
            {/* <Price/> */}
            {/* <OurBarbers/> */}
            {/* <MakingSection/> */}
            {/* <News/> */}
            <Review/>
            <Brands/>
        <Footer/>
       </>
    )
}