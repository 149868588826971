import React from 'react'

import Footer from './../Footer';
import Header from './../Header';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { addQuantity, removeItem, subtractQuantity } from '../../actions/CartAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Products

import Category from './../assets/img/promo-1.jpg';
import Category2 from './../assets/img/promo-2.jpg';
import Category3 from './../assets/img/promo-3.jpg';


function Cart() {

    const dispatch = useDispatch();

    const removeItems = (pro) => {
        dispatch(removeItem(pro));
    }

    const addQuantities = (pro) => {
        dispatch(addQuantity(pro));
    }

    const minusqty = (pro) => {
        dispatch(subtractQuantity(pro));
    }

    const CartProducts = useSelector((state) => state.cart)
    return (
        <>
            <ToastContainer />
            <Header />

            <section id="team" className="team_section bd-bottom padding">
                <div className="container">
                    <div className="section_heading text-center mb-40 mt-40 wow fadeInUp" data-wow-delay="300ms">
                        <h3>Hair We Cut</h3>
                        <h2>Cart Products</h2>
                        <div className="heading-line"></div>
                    </div>

                    {/* Products */}



                    <div className="row  ">
                        <div className="col-md-10 offset-1">


                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Image</th>
                                        <th width="130">Product Name</th>
                                        <th>Description</th>
                                        <th>Pound</th>
                                        <th width="130">Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CartProducts.addedItems.map((product, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>
                                                <img src={'https://hairwecut.co.uk/portal/products/' + product.img} className="img-fluid" alt="img" width="300" />
                                                {/* <img src={'http://127.0.0.1:8000/products/' + product.img} className="img-fluid" alt="img" width="300" /> */}
                                            </td>
                                            <td>{product.product_name}</td>
                                            <td><p className="text-justify">{product.slug}</p></td>
                                            <td><i class="fa fa-gbp" aria-hidden="true"></i>

                                                {(product.dprice == 0) ? product.sale_price :product.dprice}
                                            </td>

                                            <td>
                                                <i className="fa fa-minus p-1" onClick={() => minusqty(product)} style={{ background: "red", color: "#fff", marginRight: "5px" }}></i>

                                                <span style={{ background: "#fff", padding: "10px" }}>{product.quantity}</span>

                                                <i className="fa fa-plus  p-1" onClick={() => addQuantities(product)} style={{ background: "green", color: "#fff", marginLeft: "5px" }}></i>
                                            </td>

                                            {/* <td><button type="button" onClick={() => removeItems(product)}><i className="fa fa-times text-danger p-1"  style={{border:"1px solid red",borderRadius:"10px"}}></i></button></td> */}
                                        </tr>

                                    ))}


                                </tbody>

                            </table>


                            {/* NET TOTAL TABLE */}

                            <div className="row">
                                <div className="col-md-6 offset-6">
                                    <table className="table table-striped">
                                        <tbody>
                                            <tr>
                                                <th>Net Total:</th>
                                                <td>{CartProducts.total}</td>
                                            </tr>
                                            <tr>
                                                <th><Link to="/product" className="btn btn-block btn-sm" style={{ background: "#9e8a78", color: "#fff", borderRadius: "0px" }}>Continue To Cat</Link></th>
                                                {(CartProducts.totalProduct == 0) ? '' :
                                                    <td><Link to="/checkout" className="btn btn-danger btn-sm btn-block">CheckOut</Link></td>
                                                }
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                        {/* <div className="col-md-3 xs-padding">
                            <div className="widget-content">
                                <h4>Recent Posts</h4>
                                <ul className="thumb-post">
                                    <li><img src={Category} alt="post" /><a href="#">Hair Cuts</a></li>
                                    <li><img src={Category2} alt="post" /><a href="#">Facials</a></li>
                                    <li><img src={Category3} alt="post" /><a href="#">Makeups</a></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                    {/* Products ./ */}
                </div>
            </section>


            <Footer />
        </>
    )
}

export default Cart
