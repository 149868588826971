import React from 'react'
import loaderimg from './../assets/img/loading.gif';

function Loader() {
    return (
        <div id='preloader'>
            <div className='loader'>
                <img src={loaderimg} width="80" alt="" />
            </div>
        </div>
    )
}

export default Loader
