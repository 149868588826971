import React from 'react'
import Footer from './../Footer';
import Header from './../Header';

import {Link} from "react-router-dom"


function Success() {

    const Cart = {
        background: "#fff",
        padding: "60px",
        borderRadius: "4px",
        width: "100%",
        boxShadow: "0 2px 3px 2px rgb(75 120 7)",
        display: "inline-block",
        margin: "0 auto",
        textAlign: "center",
    };

    const Topdiv = {
        borderRadius  : "200px",
        height        : "200px",
        width         : "200px",
        background    : " #F8FAF5",
        margin        : "0 auto",
        display       : "flex",
        justifyContent: "center",
        alignItems    : "center",
    };

    const h1 = {
        fontFamily  : "Nunito Sans, Helvetica Neue, sans-serif",
        fontWeight  : " 900",
        fontSize    : "10px !important",
        marginBottom: " 10px"
    };

    const p = {
        color     : " #404F5E",
        fontSize  : "20px",
        margin    : "0",
        fontFamily: "Nunito Sans, Helvetica Neue, sans-serif",
    };

    const i = {
        color     : "rgb(75 120 7)",
        fontSize  : "100px",
        lineHeight: "200px",
        marginLeft: "-15px",
    };

    return (
        <>
            <Header />
            <div className="row" style={{ paddingTop: "20px" }}>
                <div className="col-md-4 offset-4 padding">
                    <div style={Cart}>

                        <div style={Topdiv}>
                            <i style={i}>✓</i>
                        </div>

                        <h2 style={{color:"rgb(75 120 7)"}}>Payment Success</h2>
                        <p stle={p}>We received your purchase request, we'll be in touch shortly!</p>
                        <Link to="/" className="btn  btn-sm" style={{background:"#9e8a78",color:"#fff",borderRadius:"0px",width:"200px"}}>Ok</Link>

                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Success
