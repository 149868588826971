import React, { useState, useEffect } from 'react';
import Axios from '../../services';

import Loader from './../section/Loader';
import Footer from './../Footer';
import Header from './../Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Products
import { useDispatch } from 'react-redux';
import { addToCart } from '../../actions/CartAction';

import Category from './../assets/img/promo-1.jpg';
import Category2 from './../assets/img/promo-2.jpg';
import Category3 from './../assets/img/promo-3.jpg';

function Products() {

    const [product, SetProduct] = useState([])
    const [Load, setLoad] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        fetchData();
        return () => {
            fetchData();
        }
    }, [Load]);




    const fetchData = () => {

        Axios.get('api/products')
            .then(res => {
                const productData = (res && res.data) || []
                SetProduct(productData)
                setLoad(false)
            })
    }

    const addtocart = (cartproduct) => {
        toast.dark("Product Added To Cart");
        dispatch(addToCart(cartproduct));
    }



    return (
        <>
            <ToastContainer />
            {(Load) ? <Loader /> :
                <>
                    <Header />

                    <section id="team" className="team_section bd-bottom padding">
                        <div className="container">
                            <div className="section_heading text-center mb-40 mt-40 wow fadeInUp" data-wow-delay="300ms">
                                <h3>Hair We Cut</h3>
                                <h2>Products</h2>
                                <div className="heading-line"></div>
                            </div>

                            {/* Products */}



                            <div className="row ">
                                <div className="col-md-10 offset-1 xs-padding">

                                    {
                                        product.map((product, i) => (
                                            (i % 2 == 0) ?
                                                <div key={i} className="row d-flex align-items-center" style={{ marginBottom: "20px", paddingBottom: "20px", borderBottom: "2px solid #eee" }}>
                                                    <div className="col-md-6 xs-padding wow fadeInLeft" data-wow-delay="300ms">
                                                        <div className="pro_cta_content">
                                                            <h3 style={{ fontSize: "25px" }}>{product.product_name}</h3>
                                                            <ul className="widget_social pricingul">
                                                                <li>Price:</li>
                                                                <li>
                                                                    {(product.dprice == 0) ? product.sale_price :

                                                                        <>
                                                                            <del className="text-danger">{product.sale_price}</del> &nbsp;
                                                                            {product.dprice}
                                                                        </>
                                                                    }
                                                                </li>
                                                            </ul>
                                                            <p className="text-justify">{product.slug}</p>
                                                            <button type="button" className="default_btn" onClick={() => addtocart(product)}><i className="fa fa-shopping-cart"></i> Add To Cart</button>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 xs-padding wow fadeInRight" data-wow-delay="300ms">
                                                        <img src={'https://hairwecut.co.uk/portal/products/' + product.img} className="img-fluid" alt="img" />
                                                        {/* <img src={'http://127.0.0.1:8000/products/' + product.img} className="img-fluid" alt="img" /> */}
                                                    </div>
                                                </div>

                                                :

                                                <div key={i} className="row d-flex align-items-center" style={{ marginBottom: "20px", paddingBottom: "20px", borderBottom: "2px solid #eee" }}>
                                                    <div className="col-md-6 xs-padding wow fadeInRight" data-wow-delay="300ms">
                                                        <img src={'https://hairwecut.co.uk/portal/products/' + product.img} className="img-fluid" alt="img" />
                                                        {/* <img src={'http://127.0.0.1:8000/products/' + product.img} className="img-fluid" alt="img" /> */}
                                                    </div>
                                                    <div className="col-md-6 xs-padding wow fadeInLeft" data-wow-delay="300ms">
                                                        <div className="pro_cta_content">
                                                            <h3 style={{ fontSize: "25px" }}>{product.product_name}</h3>
                                                            <ul className="widget_social pricingul">
                                                                <li>Price:</li>
                                                                <li>
                                                                    {(product.dprice == 0) ? product.sale_price :

                                                                        <>
                                                                            <del className="text-danger">{product.sale_price}</del> &nbsp;
                                                                            {product.dprice}
                                                                        </>
                                                                    }

                                                                    &nbsp; {product.currency}</li>
                                                            </ul>
                                                            <p className="text-justify">{product.slug}</p>
                                                            <button type="button" className="default_btn" onClick={() => addtocart(product)}><i className="fa fa-shopping-cart"></i> Add To Cart</button>
                                                        </div>
                                                    </div>
                                                </div>



                                        ))
                                    }
                                </div>
                            </div>
                            {/* Products ./ */}
                        </div>
                    </section>
                    <Footer />
                </>
            }
        </>
    )
}

export default Products
