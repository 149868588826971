import axios from 'axios';

const Axios = axios.create({
  baseURL: 'https://hairwecut.co.uk/portal/',
  // baseURL: 'http://127.0.0.1:8000/',
  // baseURL: 'https://hairwecut.co.uk/hairwe/',
  // baseURL: 'https://hawec.com/portal/',
  
  timeout: 500000,
});

export default Axios;