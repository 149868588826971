import React from 'react';

import footerlogo from './assets/img/final-logo.png';

import {Link} from 'react-router-dom'


export default function Footer() {
    return (
        <>
            <section className="widget_section padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-md-4 sm-padding">
                            <div className="footer_widget">
                                <img className="mb-15 rounded-circle bg-white" src={footerlogo} alt="Brand" width="150" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 sm-padding">
                            <div className="footer_widget">
                                {/* <img className="mb-15 rounded-2" src={footerlogo} alt="Brand" width="150" /> */}
                                <p>Our barbershop is the created for men who appreciate premium quality, time and flawless look.
                                </p>
                                <ul className="widget_social">
                                    <li><a href="#"><i className="social_facebook"></i></a></li>
                                    <li><a href="#"><i className="social_twitter"></i></a></li>
                                    <li><a href="#"><i className="social_googleplus"></i></a></li>
                                    <li><a href="#"><i className="social_instagram"></i></a></li>
                                    <li><a href="#"><i className="social_linkedin"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 sm-padding">
                            <div className="footer_widget">
                                <h3>UseFull Links</h3>
                                <p>
                                    <Link to="/terms" className="text-white">Terms & Condition</Link>
                                </p>
                            </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-6 sm-padding">
                            <div className="footer_widget">
                                <h3>Opening Hours</h3>
                                <ul className="opening_time">
                                    <li>Monday - Friday 11:30am - 2:008pm</li>
                                    <li>Saturday – Monday: 9am – 8pm</li>
                                    <li>Monday - Friday 5:30am - 11:008pm</li>
                                    <li>Saturday - Sunday 4:30am - 1:00pm</li>
                                </ul>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-3 col-md-12 sm-padding">
                            <div className="footer_widget">
                                <h3>Subscribe to our contents</h3>
                                <div className="subscribe_form">
                                    <form action="#" className="subscribe_form">
                                        <input type="email" name="email" id="subs-email" className="form_input"
                                            placeholder="Email Address..." />
                                        <button type="submit" className="submit">SUBSCRIBE</button>
                                        <div className="clearfix"></div>
                                        <div id="subscribe-result">
                                            <p className="subscription-success"></p>
                                            <p className="subscription-error"></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <a data-scroll href="#header" id="scroll-to-top"><i className="arrow_up"></i></a>
        </>
    )
}
