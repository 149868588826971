import React, { Component }  from 'react';

// SLike SLider
import Slider from "react-slick";



export default class Review extends Component{
  render() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000
    };

    return (

        <section id="reviews" className="testimonial_section padding">
            <div className="container">
             <Slider {...settings}>
                <div className="testimonial_item">
                    <p>"There are design companies, and then there are user experience design interface design<br />
                                    professional. By far one of the worlds best known brands."</p>
                    <h4 className="text-white">Anita Tran, IT Solutions.</h4>
                </div>

                <div className="testimonial_item">
                    <p>"There are design companies, and then there are user experience design interface design<br />
                                    professional. By far one of the worlds best known brands."</p>
                    <h4 className="text-white">Anita Tran, IT Solutions.</h4>
                </div>

                <div className="testimonial_item">
                    <p>"There are design companies, and then there are user experience design interface design<br />
                                    professional. By far one of the worlds best known brands."</p>
                    <h4 className="text-white">Anita Tran, IT Solutions.</h4>
                </div>

                <div className="testimonial_item">
                    <p>"There are design companies, and then there are user experience design interface design<br />
                                    professional. By far one of the worlds best known brands."</p>
                    <h4 className="text-white">Anita Tran, IT Solutions.</h4>
                </div>
            </Slider>
                
            </div>
        </section>
    );
  }
}
