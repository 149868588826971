import React, { useState } from 'react';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { Link, useHistory } from "react-router-dom";
import Axios from '../../services';
import { orderPlace } from '../../actions/CartAction';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './../section/Loader';


async function stripeTokenHandler(token, data) {
  const paymentData = { token: token.id, data };

  const ax = Axios.post('api/customer/payment', paymentData)
    .then(res => {
      return res.data || { success: false }
    })
  return ax;
};




const CheckoutForm = (props) => {
  console.log(props,'props');
  // const amount = props.data.amount.amount;

  const [payment, setPayment] = useState(false)
  const [Load, setLoad] = useState(false)
  const history = useHistory()

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    setLoad(true)
    const { stripe, elements, data } = props;

    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardElement);

    const result = await stripe.createToken(card);

    if (result.error) {
      console.log(Load,'Load:')
      console.log("Erroe:", result.error.message);
    } else {

      stripeTokenHandler(result.token, data).then(data => {
        if (data.success) {
          setLoad(false)
          setPayment(true)
          history.push("/success")
        }
      })
    }

  };

  return (
    <>
      {(Load) ?
        <Loader />
        : ''
      }

      {
        (payment == false) ?

          <form onSubmit={handleSubmit}>
            <input name="id" type="hidden" value={props.data.id} />
            <div className="form-group">
              {
                props.data.amount !== undefined ? (
                  <input name="amount" className="form-control" value={ props.data.amount} readOnly />
                  )
                  :
                  null
              }
            </div>
            <div className="form-control">
              <CardElement />

            </div>
            <button type="submit" className="btn btn-block" style={{ background: "#9e8a78", color: "#fff", borderRadius: "0px" }} disabled={!props.stripe}>Pay</button>
          </form>
          :
          <Link to="/dashboard"> <button type="button" className="btn btn-block" style={{ background: "#9e8a78", color: "#fff", borderRadius: "0px" }}>Back To Dashboard</button></Link>
      }
    </>

  )
}




export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CheckoutForm elements={elements} stripe={stripe} data={props} />
      )}
    </ElementsConsumer>
  );
}